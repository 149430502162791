<template>
  <!-- 顶部轮播图 -->
  <div class="slideshowBox">
    <div class="navBox">
      <div class="logo" @click="refresh()">
        <div style="height: 47px;width: 200px;">
          <img src="./images/logo@2x.png" alt="" @click="goHome()" />
        </div>
      </div>
      <div class="navBar">
        <el-menu :default-active="activeIndex2" class="el-menu-demo" mode="horizontal" @select="handleSelect"
          background-color="#fff" text-color="#333" active-text-color="#3964E6">
          <el-menu-item index="/Home/FirstPage">首页</el-menu-item>
          <!-- <el-menu-item index="/Home/Homepage">AI志愿</el-menu-item> -->
          <el-submenu index="3">
            <template slot="title">查数据</template>
            <el-menu-item index="/Home/SearchUniversity">找大学</el-menu-item>
            <el-menu-item index="/Home/CheckTheMajor">查专业</el-menu-item>
            <el-menu-item index="/Home/Occupation">看职业</el-menu-item>
          </el-submenu>
          <!-- <el-menu-item index="4">专业测评</el-menu-item> -->
          <el-submenu index="5">
            <template slot="title">填报工具箱</template>
            <el-menu-item index="/Home/BatchLine">批次线</el-menu-item>
            <el-menu-item index="/Home/RankLookup">查位次</el-menu-item>
            <el-menu-item index="/Home/ExpansionAndContraction">扩缩招</el-menu-item>
            <el-menu-item index="/Home/ListRanking">院校排行</el-menu-item>
            <el-menu-item index="/Home/News">高考资讯</el-menu-item>
          </el-submenu>
          <!-- <el-menu-item index="6">强基/综评</el-menu-item> -->
          <el-menu-item index="7">港澳台/留学</el-menu-item>
          <el-menu-item index="8">生涯规划</el-menu-item>
          <!-- <el-menu-item index="8"><a style="text-decoration: none;" href="https://www.baidu.com"
              target="_blank">生涯规划</a></el-menu-item> -->
        </el-menu>
      </div>
      <div class="userInfo">
        <div class="dq" @click="xzsf()">
          <i class=" el-icon-location-outline" style="font-size: 18px; margin-right: 5px; color: #3964E6;"></i>
          <div style="font-size: 20px;">{{ userInfoObj.AreaName || '选择地区' }}</div>
        </div>
        <div class="info" v-if="userInfoObj.NickName == '' && userInfoObj.Phone == ''">
          <i class="el-icon-user" style="font-size: 18px;margin-right: 5px; color: #3964E6;"></i>
          <div style="font-size: 20px;font-weight: 600;">登录</div>
        </div>
        <el-popover v-else placement="bottom" trigger="click">
          <div class="user-menu___OA9iU">
            <div class="user-menu-item___SfQSJ" style="display: block;" @click="goPersonalCenter(0)"><i
                class="el-icon-user"></i> <span style="margin-left: 10px;">账号信息</span></div>
            <!-- <div class="user-menu-item___SfQSJ" style="display: block;">我的成绩</div> -->
            <div class="user-menu-item___SfQSJ" style="display: block;" @click="goTab()"><i class="el-icon-s-order"></i>
              <span style="margin-left: 10px;">我的志愿表</span>
            </div>
            <!-- <div class="user-menu-item___SfQSJ" style="display: block;">我的测评</div> -->
            <div class="user-menu-item___SfQSJ" style="display: block;" @click="goPersonalCenter(1)"><i
                class="el-icon-shopping-cart-full"></i> <span style="margin-left: 10px;">我的订单</span></div>
            <div class="user-menu-item___SfQSJ" style="display: block;color: #F11717;" @click="laouyt()"><i
                class="el-icon-switch-button"></i> <span style="margin-left: 10px;">退出登录</span></div>
            <!-- <div class="user-menu-item___SfQSJ" style="display: block;">开通VIP</div> -->
            <!-- <div class="user-menu-item___SfQSJ" style="display: block;">我的订单</div> -->
            <!-- <div class="user-menu-item___SfQSJ" style="display: block;">修改密码</div> -->
          </div>

          <div slot="reference" class="info">
            <div class="name" style="max-width: 200px;overflow: hidden;">{{ userInfoObj.NickName !== '' ?
        userInfoObj.NickName :
        '新用户' }}</div>
            <div class="NoVip" v-if="this.$store.state.user.studentInfo.IsVIP"><img src="./images/VIP备份 2@2x.png"
                alt="">
            </div>
            <div class="NoVips" v-else @click.stop="goVipParticulars()">
              <div style="font-size: 16px;color: #FFFFFF;">开通</div>
              <div style="width: 19px; height: 17px;"><img src="./images/VIP备份 2@2x.png" alt=""></div>
            </div>
          </div>
        </el-popover>




      </div>
      <!-- 省份选择框 -->
      <div class="cityFrame" v-if="provinceIsShow">
        <!-- <div class="cityFrame" v-if="false"> -->
        <div class="home-city">
          <h1>请选择你所在的高考省份</h1>
          <ul class="font0">
            <li :class="it.ProvinceName == ProvinceName ? 'city-btn-bg' : ''" v-for="it in listInit" :key="it.Code"
              @click="selectState(it)">
              {{ it.ProvinceName }}</li>
          </ul>
          <div class="city-btn boder-radius4 cursorPointer" @click="confirm()">确认</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetSeVolunteerInitialization, UpdateCustomer, GetCustomerInfoById } from "@/api/home";
import { getId } from "@/utils/auth.js";
import { removeToken, removeId } from "@/utils/auth.js";
export default {
  name: 'OCRrecognitionIndex',
  components: {
  },
  data() {
    return {
      ProvinceName: '',
      listInit: [],
      userInfo: {},
      activeIndex2: '',
      shower: 0,
      provinceIsShow: false,

    }
  },
  watch: {
    $route: {
      handler(val) {
        console.log(val);//新路由信息
        this.activeIndex2 = val.path
      },
      // 深度观察监听
      immediate: true
    },
    test: {
      immediate: true,
      handler: function (newval) {
        this.provinceIsShow = newval
      }
    },
    userInfoObj: {
      immediate: true,
      handler: function (newval) {
        this.userInfo = newval
        this.ProvinceName = newval.AreaName
      }
    },
  },
  //1.在computed中把数据返回
  computed: {
    test() {
      return this.$store.state.user.provinceShow
    },
    userInfoObj() {
      return this.$store.state.user.studentInfo
    },
  },

  mounted() {
    this.getSeVolunteerList()
  },

  methods: {
    refresh() {
      this.$router.go(0); // 刷新当前页面
    },
    goVipParticulars() {
      this.$router.push('/Home/VipParticulars')
    },
    laouyt() {
      this.$confirm('确定退出登录？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        removeToken()
        removeId()
        window.location.href = process.env.VUE_APP_SSO_URL;
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消退出'
        });
      });
    },
    goPersonalCenter(types) {
      if (types == 0) {
        this.$router.push('/Home/PersonalCenter')
      } else {
        this.$router.push('/Home/PersonalCenters')
      }
    },
    goTab() {
      this.$router.push('/Home/zyTable')
    },
    // 更新省份信息
    async confirm() {
      this.$store.commit("user/setProvinceShow", false)
      await UpdateCustomer({ studentId: getId(), areaName: this.ProvinceName })
      this.getUserInfo(getId())
    },
    // 选择省份
    selectState(it) {
      this.ProvinceName = it.ProvinceName
    },
    // 获取个人信息
    async getUserInfo() {
      const res = await GetCustomerInfoById(
        {
          Id: getId(),
        }
      )
      this.$store.commit('user/setStudentInfo', res.data.response)
    },
    // 获取高考年份type
    async getSeVolunteerList() {
      const res = await GetSeVolunteerInitialization()
      this.listInit = res.data.response
    },
    xzsf() {
      this.show = !this.show
      this.$store.commit("user/setProvinceShow", this.show)
    },
    goHome() {
      this.$router.push('/Home/FirstPage')
    },
    handleSelect(key, keyPath) {
      if (key == 7) {
        this.activeIndex2 = key
        window.open('https://www.ycymedu.com/Home/StudyingAbroad', '_blank')
      } if (key == 8) {
        this.activeIndex2 = key
        window.open('http://ceping.ycymedu.com/?token=' + this.$store.state.user.serviceToken, '_blank')
      } else {
        this.activeIndex2 = key
        this.$router.push(key)
      }
      console.log('-------', key, keyPath);
      // if (key == '1') {
      // } else if (key == '2') {
      //   this.$router.push('/Home/Homepage')
      // } else if (key == '3-1') {
      //   this.$router.push('/Home/SearchUniversity')
      // } else if (key == '5-1') {
      //   this.$router.push('/Home/BatchLine')
      // } else if (key == '5-2') {
      //   this.$router.push('/Home/RankLookup')
      // } else if (key == '5-4') {
      //   this.$router.push('/Home/ListRanking')
      // } else if (key == '5-5') {
      //   this.$router.push('/Home/News')
      // } else if (key == '3-2') {
      //   this.$router.push('/Home/CheckTheMajor')
      // } else if (key == '5-3') {
      //   this.$router.push('/Home/ExpansionAndContraction')
      // } else if (key == '3-3') {
      //   this.$router.push('/Home/Occupation')
      // }
    },
  },
};
</script>
<style></style>
<style lang="less" scoped>
::v-deep .el-submenu__icon-arrow {
  font-size: 20px;
}

.user-menu___OA9iU {
  padding: 20px 22px;

  .user-menu-item___SfQSJ {
    font-size: 18px;
    font-weight: 400;
    color: #333;
    line-height: 28px;
    margin-bottom: 22px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .user-menu-line___mwoOJ {
    width: 190px;
    height: 1px;
    border-bottom: 1px solid #efefef;
  }

  .logout___m8_S5 {
    font-size: 16px;
    font-weight: 400;
    color: #272727;
    line-height: 28px;
    margin-top: 25px;
    text-align: center;
    cursor: pointer;
  }
}




.cityFrame {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  background-color: rgba(0, 0, 0, .6);

  .home-city {
    width: 1028px;
    max-height: 465px;
    margin: 0 auto;
    color: #333;
    background-color: #fff;
    filter: progid:DXImageTransform.Microsoft.gradient(startcolorstr=rgba(127, 0, 0, 0), endcolorstr=rgba(127, 0, 0, 0));
    border-radius: 18px;
    position: absolute;
    left: calc(50% - 514px);
    top: calc(50% - 232.5px);
    padding: 50px 0;

    h1 {
      font-weight: 500;
      font-size: 24px;
      color: #333;
      text-align: center;
      margin-top: 40px;
    }

    ul {
      width: 937px;
      text-align: left;
      margin: 27px auto 0;

      li {
        display: inline-block;
        font-size: 16px;
        border: 1px solid #eee;
        border-radius: 8px;
        min-width: 88px;
        text-align: center;
        height: 40px;
        line-height: 40px;
        margin: 10px 7px;
        background-color: rgb(248, 248, 248);
        color: rgb(203, 203, 203);
        cursor: pointer;
      }

      li:hover {
        color: #00BF67 !important;
      }

      .city-btn-bg {
        color: #00BF67 !important;
        border: 1px solid #00BF67 !important;
      }

    }

    .city-btn {
      background-color: #00BF67;
      color: #fff;
      text-align: center;
      line-height: 51px;
      font-size: 18px;
      margin: auto;
      margin-top: 40px;
      border-radius: 12px;
      width: 191px;
      height: 51px;
      opacity: 1;
      cursor: pointer;
    }
  }
}

::v-deep .el-submenu__title:hover {
  background-color: #fff !important;
  color: #3964E6 !important;
}

::v-deep.el-menu-item:hover {
  color: #3964E6 !important;
  background-color: #fff !important;
}

::v-deep .el-menu.el-menu--horizontal {
  border-bottom: none;
}

::v-deep .el-menu--horizontal>.el-menu-item.is-active {
  color: #303133;
  font-weight: 600;
}

::v-deep .el-menu--horizontal>.el-submenu.is-active {
  color: #303133;
  font-weight: 600;
}

::v-deep .el-menu--horizontal>.el-menu-item {
  height: 50px;
  line-height: 50px;
  font-size: 22px;
}



::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title {
  height: 50px;
  line-height: 50px;
  font-size: 22px;
}

img {
  width: 100%;
  height: 100%;
}


.slideshowBox {
  min-width: 1243px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  width: 100%;
  font-family: PingFangSC-Regular, PingFang SC;
  background: #fff;
  height: 80px;
  z-index: 22;

  .navBox {
    background: #fff;
    width: 100%;
    min-width: 1920px;
    height: 80px;
    overflow-y: auto;
  }

  .logo {
    cursor: pointer;
    z-index: 23;
    position: absolute;
    top: 20%;
    left: 5%;
    height: 50px;
  }

  .userInfo {
    position: absolute;
    top: 30%;
    right: calc((100% - 1343px)/2);
    display: flex;
    align-items: center;
    z-index: 222;
    cursor: pointer;

    .dq {
      text-align: center;
      line-height: 30px;
      min-width: 80px;
      padding: 0 5px;
      height: 30px;
      border-radius: 10px;
      border: 1px solid #3964E6;
      margin-right: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: center;

      .name {
        font-size: 20px;
        font-weight: 600;
        margin-right: 5px;
      }

      .NoVip {
        width: 25px;
        height: 21px;
      }

      .NoVips {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 88px;
        height: 35px;
        background: #F46A3E;
        border-radius: 5px;
      }
    }
  }


  .navBar {
    cursor: pointer;
    z-index: 22;
    position: absolute;
    top: 30%;
    transform: translate(-50%, 0);
    left: 45%;
    height: 30px;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    .nav {
      margin-left: 72px;
    }

    .nav:nth-child(1) {
      margin-left: 82px !important;
    }

    .nav:hover {
      color: #3964e6;
    }

    .navActive {
      color: #3964e6;
      font-weight: bold;
    }
  }
}
</style>
