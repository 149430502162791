<template>
    <div class="box">
        <div class="swp">
            <Swiper />
            <div class="choiceW">
                <div class="choiceBox">
                    <div class="year" style="margin-top: 20px;">
                        <span style="width: 100%; font-weight: bold;">2024 志愿模拟填报</span>

                    </div>
                    <div class="year" style="margin-top: 20px;">
                        <span>高考年份</span>
                        <el-select value-key="code" v-model="value" placeholder="请选择年份" @change="selectChanged">
                            <el-option v-for="(item, index) in ProvinceObj.lizations" :key="index" :label="item.code"
                                :value="item">
                            </el-option>
                        </el-select>
                    </div>
                    <!-- <div class="mold">
                    <span>成绩类型</span>
                    <el-radio v-model="radio" label="1">本科</el-radio>
                    <el-radio v-model="radio" label="2">专科</el-radio>
                </div> -->
                    <div class="subjects">
                        <span>高考科目</span>
                        <div class="subject" v-if="Policy == 0">
                            <div :class="it.CR ? 'item active' : 'item '" v-for="(it, index) in oldsubject" :key="index"
                                @click="cliSub(index, it)">{{ it.name }}</div>
                        </div>
                        <div class="subject" v-if="Policy == 1">
                            <div :class="it.CR ? 'item active' : 'item '" v-for="(it, index) in newsubject" :key="index"
                                @click="cliSub(index, it)">{{ it.name }}</div>
                        </div>
                        <div class="subject" v-if="Policy == 11">
                            <div :class="it.CR ? 'item active' : 'item '" v-for="(it, index) in newsubject1"
                                :key="index" @click="cliSub(index, it)">{{ it.name }}</div>
                        </div>
                        <div class="subject" v-if="Policy == 2">
                            <div :class="it.CR ? 'item active' : 'item '" v-for="(it, index) in newsubject" :key="index"
                                @click="cliSub(index, it)">{{ it.name }}</div>
                        </div>
                    </div>
                    <div class="grade">
                        <span>分数排名</span>
                        <el-input :disabled="ScoreCount == 0" v-model="grade" type="number"
                            placeholder="请输入分数1-750"></el-input>
                        <span class="f">分</span>
                    </div>
                    <div class="grade">
                        <span></span>
                        <el-input :disabled="ScoreCount == 0" v-model="ranking" type="number"
                            placeholder="请输入对应排名"></el-input>
                    </div>
                    <div style="display: flex;align-items: center;width: 100%;justify-content: center;">
                        <div style="font-size: 14px; color: red;margin-top: 5px;">今日还可修改 {{ ScoreCount }} 次成绩</div>
                    </div>
                    <div :class="ProvinceName == '浙江省' ? 'btnss btn' : 'btn'">
                        <div class="but" @click="goHomepage(1)">
                            智能选大学
                        </div>
                        <div class="but1" @click="goHomepage(2)">
                            一键填报
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tabs">
            <div class="item" @click="goDiplomaMill('/Home/SearchUniversity')">
                <div class="imgs">
                    <img src="./images/找大学@2x.png" alt="">
                </div>
                <div class="text">找大学</div>
            </div>
            <div class="item" @click="goDiplomaMill('/Home/CheckTheMajor')">
                <div class="imgs">
                    <img src="./images/查专业@2x.png" alt="">
                </div>
                <div class="text">查专业</div>
            </div>
            <div class="item" @click="goDiplomaMill('/Home/Occupation')">
                <div class="imgs">
                    <img src="./images/看职业@2x.png" alt="">
                </div>
                <div class="text">看职业</div>
            </div>
            <div class="item" @click="goDiplomaMill('/Home/BatchLine')">
                <div class="imgs">
                    <img src="./images/批次线@2x.png" alt="">
                </div>
                <div class="text">批次线</div>
            </div>
            <div class="item" @click="goDiplomaMill('/Home/RankLookup')">
                <div class="imgs">
                    <img src="./images/查位次@2x.png" alt="">
                </div>
                <div class="text">查位次</div>
            </div>
            <!-- <div class="item" @click="goDiplomaMill('/Home/DiplomaMill')">
                <div class="imgs">
                    <img src="./images/招生计划@2x.png" alt="">
                </div>
                <div class="text">招生计划</div>
            </div> -->
            <div class="item" @click="goDiplomaMill('/Home/ExpansionAndContraction')">
                <div class="imgs">
                    <img src="./images/查扩@2x.png" alt="">
                </div>
                <div class="text">查扩缩招</div>
            </div>
            <div class="item" @click="goDiplomaMill('/Home/DiplomaMill')">
                <div class="imgs">
                    <img src="./images/野鸡大学@2x.png" alt="">
                </div>
                <div class="text">大学甄别</div>
            </div>

        </div>
        <!-- <div class="appraisal">
            <div class="title">专业测评</div>
            <div class="list">
                <div class="item">
                    <img src="./images/Group 24@2x.png" alt="">
                </div>
                <div class="item">
                    <img src="./images/Group 25@2x.png" alt="">
                </div>
                <div class="item">
                    <img src="./images/Group 26@2x.png" alt="">
                </div>
                <div class="item">
                    <img src="./images/Group 28@2x.png" alt="">
                </div>
            </div>
        </div> -->
        <div class="counselor">
            <div class="title">
                <div class="text">高报咨询师</div>
                <div class="more" @click="goT()">查看更多</div>
            </div>
            <div class="list">
                <div class="item">
                    <div class="userInfo">
                        <div class="phone">
                            <img src="./images/龙和平.jpg" alt="">
                        </div>
                        <div class="info">
                            <div class="name">特邀专家——龙和平</div>
                            <div class="name" style="font-size: 14px;">山东地区 从业26年</div>
                            <div class="tag">
                                <div class="tagItem">志愿填报</div>
                                <div class="tagItem">高招政策解读</div>
                                <div class="tagItem">学业规划</div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="subscribe"><a href="https://boss.ycymedu.com/zhuan-jia-1" target="_blank" -->
                            <!-- rel="noopener noreferrer">立即预约</a></div> -->
                </div>
                <div class="item">
                    <div class="userInfo">
                        <div class="phone">
                            <img src="./images/李振.jpg" alt="">
                        </div>
                        <div class="info">
                            <div class="name">特邀专家——李振</div>
                            <div class="name" style="font-size: 14px;">山东地区 从业20年</div>
                            <div class="tag">
                                <div class="tagItem">志愿填报</div>
                                <div class="tagItem">高招政策解读</div>
                                <div class="tagItem">艺考传媒</div>
                                <div class="tagItem">学业规划</div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="subscribe"><a href="https://boss.ycymedu.com/zhuan-jia-1" target="_blank" -->
                            <!-- rel="noopener noreferrer">立即预约</a></div> -->
                </div>
                <div class="item">
                    <div class="userInfo">
                        <div class="phone">
                            <img src="./images/张勇.jpg" alt="">
                        </div>
                        <div class="info">
                            <div class="name">金牌专家——张勇</div>
                            <div class="name" style="font-size: 14px;">山东地区</div>
                            <div class="tag">
                                <div class="tagItem">志愿填报</div>
                                <div class="tagItem">学业规划</div>
                                <div style="opacity: 0;" class="tagItem"></div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="subscribe"><a href="https://boss.ycymedu.com/zhuan-jia-1" target="_blank" -->
                            <!-- rel="noopener noreferrer">立即预约</a></div> -->
                </div>
                <div class="item">
                    <div class="userInfo">
                        <div class="phone">
                            <img src="./images/朱霞.jpg" alt="">
                        </div>
                        <div class="info">
                            <div class="name">金牌专家——朱霞</div>
                            <div class="name" style="font-size: 14px;">山东地区</div>
                            <div class="tag">
                                <div class="tagItem">志愿填报</div>
                                <div class="tagItem">艺考传媒</div>
                                <div class="tagItem">学业规划</div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="subscribe"><a href="https://boss.ycymedu.com/zhuan-jia-1" target="_blank" -->
                            <!-- rel="noopener noreferrer">立即预约</a></div> -->
                </div>
                <div class="item">
                    <div class="userInfo">
                        <div class="phone">
                            <img src="./images/牟少霞.png" alt="">
                        </div>
                        <div class="info">
                            <div class="name">金牌专家——牟少霞</div>
                            <div class="name" style="font-size: 14px;">山东地区</div>
                            <div class="tag">
                                <div class="tagItem">志愿填报</div>
                                <div class="tagItem">学业规划</div>
                                <div style="opacity: 0;" class="tagItem"></div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="subscribe"><a href="https://boss.ycymedu.com/zhuan-jia-1" target="_blank" -->
                            <!-- rel="noopener noreferrer">立即预约</a></div> -->
                </div>
                <div class="item">
                    <div class="userInfo">
                        <div class="phone">
                            <img src="./images/刘志珍.png" alt="">
                        </div>
                        <div class="info">
                            <div class="name">金牌专家——刘志珍</div>
                            <div class="name" style="font-size: 14px;">山东地区</div>
                            <div class="tag">
                                <div class="tagItem">志愿填报</div>
                                <div class="tagItem">学业规划</div>
                                <div style="opacity: 0;" class="tagItem"></div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="subscribe"><a href="https://boss.ycymedu.com/zhuan-jia-1" target="_blank" -->
                            <!-- rel="noopener noreferrer">立即预约</a></div> -->
                </div>
                <div class="item">
                    <div class="userInfo">
                        <div class="phone">
                            <img src="./images/魏老师.jpg" alt="">
                        </div>
                        <div class="info">
                            <div class="name">特邀专家——魏老师</div>
                            <div class="name" style="font-size: 14px;">上海地区 从业15年</div>
                            <div class="tag">
                                <div class="tagItem">志愿填报</div>
                                <div class="tagItem">高招政策解读</div>
                                <div class="tagItem">综合素质拓展</div>
                                <div class="tagItem">学业规划</div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="subscribe"><a href="https://boss.ycymedu.com/zhuan-jia-1" target="_blank" -->
                            <!-- rel="noopener noreferrer">立即预约</a></div> -->
                </div>
                <div class="item">
                    <div class="userInfo">
                        <div class="phone">
                            <img src="./images/刘老师.jpg" alt="">
                        </div>
                        <div class="info">
                            <div class="name">金牌专家——刘老师</div>
                            <div class="name" style="font-size: 14px;">上海地区</div>
                            <div class="tag">
                                <div class="tagItem">志愿填报</div>
                                <div class="tagItem">职业规划</div>
                                <div class="tagItem">硕士升学</div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="subscribe"><a href="https://boss.ycymedu.com/zhuan-jia-1" target="_blank" -->
                            <!-- rel="noopener noreferrer">立即预约</a></div> -->
                </div>
                <div class="item">
                    <div class="userInfo">
                        <div class="phone">
                            <img src="./images/张金鲜.jpg" alt="">
                        </div>
                        <div class="info">
                            <div class="name">金牌专家——张老师</div>
                            <div class="name" style="font-size: 14px;">上海地区</div>
                            <div class="tag">
                                <div class="tagItem">志愿填报</div>
                                <div class="tagItem">心理辅导</div>
                                <div class="tagItem">职业规划</div>
                                <div style="opacity: 0;" class="tagItem"></div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="subscribe"><a href="https://boss.ycymedu.com/zhuan-jia-1" target="_blank" -->
                            <!-- rel="noopener noreferrer">立即预约</a></div> -->
                </div>
            </div>
        </div>
        <div class="base">
            <div class="left">
                <div class="title">高考资讯</div>
                <div class="list" v-for="(it, idx) in Newslist" :key="idx" v-show="idx <= 20"
                    @click="goArticlelist(it.Id)">
                    <div class="headline">{{ it.Title }}</div>
                    <div class="contents">{{ it.CreateTime }}</div>
                </div>
                <div class="gd" style="cursor: pointer;" @click="goNew()">查看更多</div>
            </div>
            <div class="right">
                <div class="ranking">
                    <div class="icon">
                        <img src="./images/榜单@2x.png" alt="">
                    </div>
                    <div>榜单排行</div>
                </div>
                <div class="items">
                    <div :class="obj.Type == it.Id ? 'it itemsActive' : 'it'" v-for="(it, index) in RankingType"
                        :key="index" @click="clickRankingType(it.Id)">{{ it.Name
                        }}</div>
                </div>
                <div class="schoolList">
                    <div class="its" v-show="idx <= 20" v-for="(it, idx)  in Rankinglist " :key="idx"
                        @click="goSchoolDetails(it.Uid)">
                        <div
                            :class="idx == 0 ? 'number one' : idx == 1 ? 'number two' : idx == 2 ? 'number three' : 'number'">
                            {{ it.Sort }}
                        </div>
                        <div class="schoolName">{{ it.Name }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部导航 -->
        <div class="typenav">
            <Particulars />
        </div>
        <!-- 会员弹框 -->
        <div class="mask" v-if="show" @click="showWs()"></div>
        <div class="vipDialog" v-if="dialogVisible">
            <div class="top">
                <div class="info">
                    <div class="photo">
                        <img :src="userInfo.AvatarUrl" alt="">
                    </div>
                    <div class="right">
                        <div class="phone">{{ userInfo.Phone == '' ? '无数据' : userInfo.phone }}</div>
                        <div class="text">
                            <img src="./images/编组 11@2x.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="jhm" @click.stop="fn()">激活码开通VIP</div>
            </div>
            <div class="vipContent">
                <div class="vipLeft">
                    <div class="vipEquity">
                        <img src="./images/编组 9@2x.png" alt="">
                    </div>
                    <div class="vipEquityText">
                        <div>
                            <span class="tt">适用考生：</span>
                            <span>新高考省份考生（不含艺术体育类）</span>
                        </div>
                        <div>
                            <span class="tt">适用批次：</span>
                            <span>普通类（不包含提前批）</span>
                        </div>
                        <div>
                            <span class="tt">使用日期：</span>
                            <span>有效期一年，有效期至{{ new Date().getFullYear() + 1 }}-{{ new Date().getMonth() + 1 }}-{{ new
                            Date().getDate() }}</span>
                        </div>
                        <div>
                            <span class="tt">购买须知：</span>
                            <span>系统仅提供志愿填报参考服务，招生计划与录取
                                数据请以学校官方或考试院公布为准。正式填报请前往考试
                                院官网，并根据官方信息，做好志愿核对。</span>
                        </div>
                    </div>
                    <div class="details" @click="goVipParticulars()">查看详情</div>

                </div>
                <div class="vipright">
                    <div class="money">¥ 298</div>
                    <div class="payment">
                        <div class="payTab">
                            <div class="wx">
                                <span></span>
                                <span>微信支付</span>
                            </div>
                            <!-- <div class="zfb">
                                <span></span>
                                <span>支付宝支付</span>
                            </div> -->
                        </div>
                        <div class="palyCode">
                            <img :src="code" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GetPcUniversityRank } from "@/api/ListRanking";
import Particulars from "@/components/Particulars"
import { GetCustomerInfoById, Callback, GetSeVolunteerInitialization, UpdateCustomer } from "@/api/home";
import { getId } from "@/utils/auth.js";
import { GetPageNews } from "@/api/News";
import { getVipCheckRoles, CheckRoles } from "@/api/vip";
import Swiper from "@/components/swiper";
export default {
    name: 'VoluntaryReportingIndex',
    components: {
        Swiper,
        Particulars
    },
    data() {
        return {
            userInfo: {},
            timerId: null, // 存放计时器ID的变量
            code: '',
            dialogVisible: false,
            ScoreCount: 0,
            show: false, // 控制遮罩层的显示与隐藏
            ProvinceName: '',
            ProvinceObj: {},
            listInit: [],
            value: 2024,//年份
            Policy: '1',//政策
            grade: '',//分数
            ranking: '',//排名
            selectOne: [],
            selectTwo: [],
            selectThree: [],
            selectFour: [],
            oldsubject: [
                {
                    name: "文科",
                    CR: false
                },
                {
                    name: "理科",
                    CR: false
                }
            ],
            newsubject: [
                {
                    name: "物",
                    CR: false
                },
                {
                    name: "史",
                    CR: false
                },
                {
                    name: "化",
                    CR: false
                },
                {
                    name: "生",
                    CR: false
                },
                {
                    name: "政",
                    CR: false
                },
                {
                    name: "地",
                    CR: false
                },
            ],
            newsubject1: [
                {
                    name: "物",
                    CR: false
                },
                {
                    name: "史",
                    CR: false
                },
                {
                    name: "化",
                    CR: false
                },
                {
                    name: "生",
                    CR: false
                },
                {
                    name: "政",
                    CR: false
                },
                {
                    name: "地",
                    CR: false
                },
                {
                    name: "技",
                    CR: false
                },
            ],
            obj: {
                Type: 0,
                Name: '',
            },
            RankingType: [
                { Id: 0, Name: "校友会" },
                { Id: 1, Name: "武书连" },
                { Id: 2, Name: "软科" },
                { Id: 3, Name: "QS" },
                // { Id: 4, Name: "泰晤士河报" },
            ],
            Rankinglist: [],
            objs: {
                CategoryId: 1,
                PageIndex: 1,
                PageSize: 10,
            },
            Newslist: [],
            IsVip: false
        };
    },
    beforeDestroy() {
        clearInterval(this.timerId); // 清除计时器
    },
    //1.在computed中把数据返回
    computed: {
        test() {
            return this.$store.state.user.provinceShow
        },
        AreaNames() {
            return this.$store.state.user.studentInfo.AreaName
        }
    },
    // 在watch中监听
    watch: {
        test: {
            immediate: true,
            handler(newval) {
                this.provinceIsShow = newval
            }
        },
        // 监听省份变化
        AreaNames: {
            immediate: true,
            handler(newval) {
                this.ProvinceName = newval
                var index = this.listInit.findIndex(item => item.ProvinceName == newval)
                if (index !== -1) {
                    this.ProvinceObj = this.listInit[index]
                    if (index !== -1) {
                        if (newval == "浙江省") {
                            this.Policy = 11
                        } else {
                            this.Policy = this.ProvinceObj.lizations[0].Policy
                        }
                        this.rest()
                    }
                }
            }
        },

    },


    mounted() {
        this.getSeVolunteerList()
        this.getCallback()
        this.getList()
        this.getNewsList()
        this.getVIP()
    },

    methods: {
        //goT() {
        //   window.open('https://boss.ycymedu.com/jie-shao', '_blank')
        // },
        fn() {
            this.$message.error('功能暂未开放，请耐心等待')
        },
        startTimer() {
            this.timerId = setInterval(() => {
                // 这里写需要执行的逻辑
                this.getVIP()
                if (this.IsVip) {
                    this.dialogVisible = false
                    this.show = false
                    clearInterval(this.timerId); // 清除计时器
                    this.$message.success('您已支付成功')
                    this.$router.go(0);//刷新页面
                }
            }, 3000); // 设置间隔为5秒
        },
        async getOpent() {
            const res = await CheckRoles({
                PayType: 0,
                CustomerId: this.$store.state.user.studentInfo.Id,
                ProductId: 5,
            })
            const bufferUrl = btoa(new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
            this.code = 'data:image/png;base64,' + bufferUrl;
            // sessionStorage.setItem('code', JSON.stringify(this.code))
        },
        showWs() {
            this.show = false
            this.dialogVisible = false
            clearInterval(this.timerId);
        },
        async getVIP() {
            const res = await getVipCheckRoles({
                CustomerId: this.$store.state.user.studentInfo.Id
            })
            console.log('会员权限', res.data);
            this.IsVip = res.data.IsVip
            this.ScoreCount = res.data.pcRole.ScoreCount
            // this.ScoreCount = 0
        },
        s() {
            this.dialogVisible = false
        },
        goVipParticulars() {
            this.$router.push('/Home/VipParticulars')
        },

        // 跳转学校详情
        goSchoolDetails(Id) {
            this.$router.push({
                path: '/Home/SchoolDetails',
                query: { Id }
            })
        },
        goNew() {
            this.$router.push('/Home/News')
        },
        // 跳转到资讯详情
        goArticlelist(id) {
            this.$router.push({ path: '/Home/Articlelist', query: { id } })
        },

        // 高考资讯
        async getNewsList() {
            const res = await GetPageNews({
                ...this.objs
            })
            this.Newslist = res.data.response.data
        },
        // 数据回填
        backfill() {
            console.log(1, '-------', this.Policy);
            this.value = this.$store.state.user.studentInfo.Year || 2024
            this.grade = this.$store.state.user.studentInfo.Expectedscore || ''
            if (this.Policy == 1) {
                var a = this.$store.state.user.studentInfo.subjectgroupName.split(',')
                a.forEach((item) => {
                    var obj = {}
                    obj.CR = true
                    obj.name = item
                    console.log(obj);
                    this.selectTwo.push(obj)
                })
                this.selectTwo.forEach((it) => {
                    var index = this.newsubject.findIndex(item => item.name == it.name)
                    this.newsubject[index].CR = true
                })
            } else if (this.Policy == 0) {
                var aa = this.$store.state.user.studentInfo.subjectgroupName.split(',')
                aa.forEach((item) => {
                    var obj = {}
                    obj.CR = true
                    obj.name = item
                    console.log(obj);
                    this.selectOne.push(obj)
                })
                this.selectOne.forEach((it) => {
                    var index = this.oldsubject.findIndex(item => item.name == it.name)
                    this.oldsubject[index].CR = true
                })
            } else if (this.Policy == 11) {
                var aaa = this.$store.state.user.studentInfo.subjectgroupName.split(',')
                aaa.forEach((item) => {
                    var obj = {}
                    obj.CR = true
                    obj.name = item
                    console.log(obj);
                    this.selectThree.push(obj)
                })
                this.selectThree.forEach((it) => {
                    var index = this.newsubject1.findIndex(item => item.name == it.name)
                    this.newsubject1[index].CR = true
                })
            } else {
                var aaaa = this.$store.state.user.studentInfo.subjectgroupName.split(',')
                aaaa.forEach((item) => {
                    var obj = {}
                    obj.CR = true
                    obj.name = item
                    console.log(obj);
                    this.selectFour.push(obj)
                })
                this.selectFour.forEach((it) => {
                    var index = this.newsubject.findIndex(item => item.name == it.name)
                    this.newsubject[index].CR = true
                })
            }
        },
        // 保存修改学生分数信息
        async commitUserInfo(obj) {
            var a = []
            obj.subject.forEach(item => {
                a.push(item.name)
            });
            console.log('分数信息', obj, '__', a.join(','), '__');
            await UpdateCustomer({ studentId: getId(), subjectgroupName: a.join(','), Expectedscore: obj.grade, Year: obj.Years })
            // this.getUserInfo(getId())
        },
        clickRankingType(id) {
            this.obj.Type = id
            this.getList()

        },
        // 获取榜单排行
        async getList() {
            const res = await GetPcUniversityRank({
                ...this.obj
            })
            this.Rankinglist = []
            this.Rankinglist = res.data.response
        },
        // 回跳获取id
        async getCallback() {
            const res = await Callback({
                code: this.$route.query.code,
                state: this.$route.query.state,
                scope: '',
            })
            if (res.data.success) {
                this.$store.commit('user/setSerToken', res.data.response.servicetoken)
                this.$store.commit('user/setTokens', res.data.response.token)
                this.$store.commit('user/setIds', res.data.response.Id)
                this.$store.commit('user/setthridtoken', res.data.response.servicetoken)
                this.getUserInfo(res.data.response.Id)
            } else {
                this.getUserInfo(getId())
            }
        },

        // 获取个人信息
        async getUserInfo(Id) {
            const res = await GetCustomerInfoById(
                {
                    Id: Id,
                }
            )
            this.userInfo = res.data.response
            if (res.data.response.AreaName == null) {
                this.$store.commit("user/setProvinceShow", true)
            }
            this.$store.dispatch("user/removeStudentInfo");
            this.$store.commit('user/setStudentInfo', res.data.response)
            console.log(111, res.data.response);
            sessionStorage.setItem('setStudentInfo', JSON.stringify(res.data.response))
            // 判断用户完善信息是否完整
            if (!res.data.response.Datainit) {
                this.$router.push('/Home/Register')
            }
            setTimeout(() => {
                this.backfill()
            }, 100)
        },


        goDiplomaMill(path) {
            this.$router.push(path)
        },

        // 选择年份
        selectChanged(e) {
            this.rest()
            this.value = e.code
            if (this.ProvinceName !== "浙江省") {
                this.Policy = e.Policy
            } else {
                this.Policy = 11
            }
        },

        // 获取高考年份type
        async getSeVolunteerList() {
            const res = await GetSeVolunteerInitialization()
            this.listInit = res.data.response
            this.ProvinceName = JSON.parse(sessionStorage.getItem("setStudentInfo")).AreaName || ""
            var index = this.listInit.findIndex(item => item.ProvinceName == this.ProvinceName)
            if (index !== -1) {
                this.ProvinceObj = this.listInit[index]
                if (index !== -1) {
                    if (this.ProvinceName == "浙江省") {
                        this.Policy = 11
                    } else {
                        this.Policy = this.ProvinceObj.lizations[0].Policy
                    }
                    this.rest()
                }
            }
        },

        cliSub(idx, it) {
            if (this.Policy == 0) {
                if (this.selectOne.length == 0) {
                    this.selectOne.push(it)
                    this.oldsubject[idx].CR = true
                } else {
                    if (this.selectOne[0].name == it.name) {
                        this.selectOne.splice(0, 1)
                        this.oldsubject[idx].CR = false
                    } else {
                        this.$message({
                            showClose: true,
                            message: '只能选择一科目',
                            type: 'warning'
                        });
                    }
                }
            } else if (this.Policy == 1) {
                var index = this.selectTwo.findIndex(item => item.name == it.name)
                if (index == -1) {
                    if (this.selectTwo.length < 3) {
                        this.selectTwo.push(it)
                        this.newsubject[idx].CR = true
                    } else {
                        this.$message({
                            showClose: true,
                            message: '只能选择3科目',
                            type: 'warning'
                        });
                    }
                } else {
                    this.selectTwo.splice(index, 1)
                    this.newsubject[idx].CR = false
                }
            } else if (this.Policy == 11) {
                var index1 = this.selectThree.findIndex(item => item.name == it.name)
                if (index1 == -1) {
                    if (this.selectThree.length < 3) {
                        this.selectThree.push(it)
                        this.newsubject1[idx].CR = true
                    } else {
                        this.$message({
                            showClose: true,
                            message: '只能选择3科目',
                            type: 'warning'
                        });
                    }
                } else {
                    this.selectThree.splice(index1, 1)
                    this.newsubject1[idx].CR = false
                }
            } else if (this.Policy == 2) {
                var index2 = this.selectFour.findIndex(item => item.name == it.name)
                if (index2 == -1) {
                    if (this.selectFour.length < 3) {
                        this.selectFour.push(it)
                        this.newsubject[idx].CR = true
                    } else {
                        this.$message({
                            showClose: true,
                            message: '只能选择3科目',
                            type: 'warning'
                        });
                    }
                } else {
                    this.selectFour.splice(index2, 1)
                    this.newsubject[idx].CR = false
                }
            }
        },
        goHomepage(pege) {
            if (this.$store.state.user.studentInfo.IsVIP || pege !== 2) {
                if (this.grade !== '') {
                    if (this.Policy == 0) {
                        if (this.selectOne.length !== 0 && this.selectOne.length == 1) {
                            if (this.grade !== '') {
                                var obj = {
                                    Years: this.value,
                                    subject: this.selectOne,
                                    grade: this.grade,
                                }
                                this.commitUserInfo(obj)
                                if (pege == 1) {
                                    setTimeout(() => {
                                        this.$router.push('/Home/Homepage')
                                    }, 500)
                                } else {
                                    setTimeout(() => {
                                        this.$router.push('/Home/OneKey')
                                    }, 500)
                                }

                            } else {
                                this.$message({
                                    showClose: true,
                                    message: '请选择正确的科目并填写分数',
                                    type: 'error'
                                });
                            }
                        } else {
                            this.$message({
                                showClose: true,
                                message: '错了哦，必须选择一科',
                                type: 'error'
                            });
                        }
                    } else if (this.Policy == 1) {
                        if (this.selectTwo.length !== 0 && this.selectTwo.length == 3) {
                            var obj1 = {
                                Years: this.value,
                                subject: this.selectTwo,
                                grade: this.grade,
                            }
                            this.commitUserInfo(obj1)
                            if (pege == 1) {
                                setTimeout(() => {
                                    this.$router.push('/Home/Homepage')
                                }, 500)
                            } else {
                                setTimeout(() => {
                                    this.$router.push('/Home/OneKey')
                                }, 500)
                            }
                        } else {
                            this.$message({
                                showClose: true,
                                message: '错了哦，必须选择三科',
                                type: 'error'
                            });
                        }
                    } else if (this.Policy == 11) {
                        if (this.selectThree.length !== 0 && this.selectThree.length == 3) {
                            var obj11 = {
                                Years: this.value,
                                subject: this.selectThree,
                                grade: this.grade,
                            }
                            this.commitUserInfo(obj11)
                            if (pege == 1) {
                                setTimeout(() => {
                                    this.$router.push('/Home/Homepage')
                                }, 500)
                            } else {
                                setTimeout(() => {
                                    this.$router.push('/Home/OneKey')
                                }, 500)
                            }
                        } else {
                            this.$message({
                                showClose: true,
                                message: '错了哦，必须选择三科，并且包含物理、历史某一科',
                                type: 'error'
                            });
                        }
                    } else if (this.Policy == 2) {
                        if (this.selectFour.length !== 0 && this.selectFour.length == 3) {
                            var index3 = this.selectFour.findIndex(item => item.name == '物')
                            var index4 = this.selectFour.findIndex(item => item.name == '史')
                            if (index3 !== -1 || index4 !== -1) {
                                var obj2 = {
                                    Years: this.value,
                                    subject: this.selectFour,
                                    grade: this.grade,
                                }
                                this.commitUserInfo(obj2)
                                if (pege == 1) {
                                    setTimeout(() => {
                                        this.$router.push('/Home/Homepage')
                                    }, 500)
                                } else {
                                    setTimeout(() => {
                                        this.$router.push('/Home/OneKey')
                                    }, 500)
                                }
                            } else {

                                this.$message({
                                    showClose: true,
                                    message: '错了哦，必须选择三科，并且包含物理、历史某一科',
                                    type: 'error'
                                });
                            }
                        } else {
                            this.$message({
                                showClose: true,
                                message: '错了哦，必须选择三科',
                                type: 'error'
                            });
                        }
                    }
                } else {
                    this.$message({
                        showClose: true,
                        message: '分数不能为空哦~',
                        type: 'error'
                    });
                }
            } else {
                // this.$message.error('会员功能，请开通会员后尝试')
                this.getOpent()
                setTimeout(() => {
                    this.dialogVisible = true
                    this.show = true
                    this.startTimer()
                }, 500)
            }

        },
        // 重置
        rest() {
            this.value = '2024'
            this.grade = ''
            this.selectOne = []
            this.selectTwo = []
            this.selectThree = []
            this.selectFour = []
            this.oldsubject = [
                {
                    name: "文科",
                    CR: false
                },
                {
                    name: "理科",
                    CR: false
                }
            ]
            this.newsubject = [
                {
                    name: "物",
                    CR: false
                },
                {
                    name: "史",
                    CR: false
                },
                {
                    name: "化",
                    CR: false
                },
                {
                    name: "生",
                    CR: false
                },
                {
                    name: "政",
                    CR: false
                },
                {
                    name: "地",
                    CR: false
                },
            ]
            this.newsubject1 = [
                {
                    name: "物",
                    CR: false
                },
                {
                    name: "史",
                    CR: false
                },
                {
                    name: "化",
                    CR: false
                },
                {
                    name: "生",
                    CR: false
                },
                {
                    name: "政",
                    CR: false
                },
                {
                    name: "地",
                    CR: false
                },
                {
                    name: "技",
                    CR: false
                },
            ]
        },
    },
};
</script>

<style lang="less" scoped>
.mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(0, 0, 0, .5);
}

.vipDialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    padding: 24px 25px 30px 25px;
    background-image: url('./images/编组 3@2x.png');
    background-size: 100%;
    border-radius: 25px;
    z-index: 1999;

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .info {
            display: flex;
            align-items: center;

            .photo {
                width: 80px;
                height: 80px;
                margin-right: 14px;
                border-radius: 50%;
                overflow: hidden;
            }

            .right {
                .phone {
                    width: 205px;
                    font-size: 30px;
                    font-weight: 600;

                    color: #333333;
                }

                .text {
                    text-align: center;
                    line-height: 34px;
                    font-size: 18px;
                    font-weight: normal;
                    color: #FFFFFF;
                    width: 205px;
                    height: 34px;
                    background: linear-gradient(151deg, #387BFF 0%, #6B4DFF 100%);
                    border-radius: 17px;
                }
            }
        }

        .jhm {
            cursor: pointer;
            width: 169px;
            height: 45px;
            text-align: center;
            line-height: 45px;
            background: linear-gradient(to right, #595263, #282537);
            border-radius: 10px;
            font-size: 20px;
            color: #FFFFFF;
        }
    }

    .vipContent {
        display: flex;
        justify-content: space-between;

        .vipLeft {
            .vipEquity {
                width: 600px;
                height: 353px;
            }

            .vipEquityText {
                box-sizing: border-box;
                width: 560px;
                height: 258px;
                background: #FFFFFF;
                box-shadow: 0px 3px 20px 0px rgba(171, 122, 24, 0.18);
                border-radius: 21px;
                margin-left: 20px;
                padding: 23px 20px;
                font-size: 20px;
                color: #666666;

                div {
                    margin-bottom: 15px;

                    .tt {
                        color: #333333;
                        font-weight: 600;
                    }
                }
            }

            .details {
                cursor: pointer;
                width: 170px;
                height: 60px;
                text-align: center;
                line-height: 60px;
                background: linear-gradient(to right, #595263, #282537);
                border-radius: 10px;
                font-size: 20px;
                margin-left: (50%-20px);
                margin-top: 24px;
                color: #FFFFFF;
            }
        }

        .vipright {
            position: relative;
            width: 358px;
            height: 715px;
            background-image: url('./images/编组 7@2x.png');
            background-size: 100%;

            .money {
                position: absolute;
                top: 132px;
                width: 260px;
                left: 50%;
                transform: translateX(-50%);
                height: 88px;
                font-size: 60px;
                font-family: AaHouDiHei;
                color: #F5D09B;
                line-height: 45px;
                text-align: center;
            }

            .payment {
                position: absolute;
                top: 280px;
                left: 50%;
                transform: translateX(-50%);
                display: flex;
                flex-direction: column;
                align-items: center;

                .payTab {
                    width: 260px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 22px;
                    margin-bottom: 59px;
                    font-size: 18px;

                    .wx {
                        cursor: pointer;
                        width: 124px;
                        height: 43px;
                        background: #28C445;
                        border-radius: 10px;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 43px;
                    }

                    .zfb {
                        cursor: pointer;
                        width: 124px;
                        height: 43px;
                        background: #FFFFFF;
                        border-radius: 10px;
                        text-align: center;
                        line-height: 43px;
                        color: #1677FF;
                    }
                }

                .palyCode {
                    width: 158px;
                    height: 158px;
                }
            }
        }
    }
}

.box {
    background: #F5F4F8;
}

img {
    width: 100%;
    height: 100%;
}

::v-deep .el-input__inner {
    padding: 1px 40px !important;
}


.swp {
    margin-bottom: 20px;
    position: relative;
    height: 400px;
    min-width: 1343px;

    .choiceW {
        width: 1343px;
    }

    .choiceBox {
        position: absolute;
        top: 0;
        right: calc((100% - 1343px)/2);
        width: 400px;
        height: 400px;
        background: #FFFFFF;
        padding: 0 25px;
        box-sizing: border-box;

        span {
            width: 72px;
            font-size: 18px;
            margin-right: 28px;
        }

        .year {
            display: flex;
            align-items: center;
            margin-top: 60px;

            .el-select {
                width: 250px;
                height: 40px;

                ::v-deep .el-input__inner {
                    border-radius: 10px;
                    padding: 1px 40px !important;
                    border: 1px solid #E1E1E1 !important;
                }
            }
        }

        .mold {
            display: flex;
            align-items: center;
            margin-top: 15px;

            ::v-deep .el-radio__input.is-checked .el-radio__inner {
                border-color: #3964E6 !important;
                background: #3964E6 !important;
            }

            ::v-deep .el-radio__input.is-checked+.el-radio__label {
                color: #3964E6;
            }
        }

        .subjects {
            display: flex;
            margin-top: 20px;

            .subject {
                cursor: pointer;
                width: 250px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;

                .item:nth-child(3) {
                    margin-right: 0px !important;
                }

                .item:nth-child(6) {
                    margin-right: 0px !important;
                }

                .active {
                    color: #FFFFFF !important;
                    border: 1px solid #3964E6 !important;
                    background: #3964E6 !important;
                }

                .item {
                    width: 62px;
                    text-align: center;
                    line-height: 29px;
                    height: 29px;
                    border-radius: 5px;
                    border: 1px solid #E1E1E1;
                    margin-bottom: 6px;
                    font-size: 16px;
                    font-weight: 400;
                }
            }
        }

        .grade {
            display: flex;
            align-items: center;
            margin-top: 10px;
            position: relative;

            .el-input {
                position: relative;
                font-size: 14px;
                display: inline-block;
                width: 250px;
            }

            .f {
                position: absolute;
                top: 8px;
                right: -70px;
            }
        }

        .btnss {
            bottom: 0px !important;
        }

        .btn {
            width: 350px;
            bottom: 20px;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .but,
            .but1 {
                width: 150px;
                height: 45px;
                border-radius: 10px;
                text-align: center;
                line-height: 45px;
                font-size: 22px;
                font-weight: 600;
                color: #FFFFFF;
                cursor: pointer;
                position: relative;
                color: #3964E6;
                text-decoration: none;
                text-transform: uppercase;
                transition: 0.5s;
                overflow: hidden;
            }

            .but1 {
                background: #3964E6;
                color: #fff;
                box-shadow: 0 0 10px #3964E6;
                transition-delay: 0.5s;
            }

            // .but:hover {
            //     background: #3964E6;
            //     color: #fff;
            //     box-shadow: 0 0 10px #3964E6;
            //     transition-delay: 0.5s;
            // }

            .but::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-top: 2px solid #3964E6;
                border-left: 2px solid #3964E6;
                transition: 0.5s;
                transition-delay: 0.5s;
            }

            .but:hover::before {
                width: 100%;
                height: 100%;
                transition-delay: 0s;
            }

            .but::after {
                content: '';
                position: absolute;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                border-bottom: 2px solid #3964E6;
                border-right: 2px solid #3964E6;
                transition: 0.5s;
                transition-delay: 0.5s;
            }

            .but:hover::after {
                width: 100%;
                height: 100%;
                transition-delay: 0s;
            }
        }
    }
}

.tabs {
    box-sizing: border-box;
    padding: 0 35px;
    width: 1343px;
    height: 200px;
    background: #fff;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        .imgs {
            width: 90px;
            height: 90px;
            transition: transform .7s ease;
        }

        .imgs:hover {
            transform: rotate(-60deg);
        }

        .text {
            margin-top: 15px;
            font-size: 20px;
        }
    }
}

.appraisal {
    width: 1343px;
    height: 657px;
    background: #FFFFFF;
    padding: 34px 29px 48px 36px;
    margin: 20px auto;
    box-sizing: border-box;

    .title {
        font-size: 22px;
        font-weight: 600;
    }

    .list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .item {
            cursor: pointer;
            margin-top: 37px;
            width: 614px;
            height: 239px;
            transition: all 0.5s ease-in-out;

        }

        .item:hover {
            transform: scale(1.11);
        }
    }

}

.counselor {
    width: 1343px;
    background: #F5F4F8;
    margin: 20px auto;

    .title {
        margin: 0 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .text {
            font-weight: 600;
            font-size: 22px;
        }

        .more {
            cursor: pointer;
            color: #4A69EA;
            font-size: 16px;
        }
    }

    .list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .item {
            padding: 28px 27px 26px 23px;
            margin-top: 30px;
            width: 406px;
            background: #FFFFFF;
            border-radius: 10px;
            box-sizing: border-box;

            .userInfo {
                display: flex;

                .phone:hover {
                    transform: scale(1.2);
                }

                .phone {
                    width: 85px;
                    height: 85px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-right: 30px;
                    transition: all 0.3s ease-in-out;
                }

                .info {
                    flex: 1;

                    .name {
                        font-weight: 600;
                        color: #333333;
                        font-size: 20px;
                        margin-bottom: 10px;
                    }

                    .tag {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        .tagItem {
                            margin-top: 8px;
                            width: 115px;
                            height: 21px;
                            background: #ECF1FF;
                            border-radius: 3px;
                            text-align: center;
                            line-height: 21px;
                        }
                    }
                }
            }

            .subscribe {
                cursor: pointer;
                margin-top: 21px;
                width: 356px;
                height: 45px;
                border-radius: 10px;
                text-align: center;
                line-height: 45px;
                font-size: 16px;
                color: #333333;
                border: 1px solid #E1E1E1;

                a {
                    display: block;
                    text-decoration: none;
                    color: #333333;
                }
            }

            .subscribe:hover {
                cursor: pointer;
                margin-top: 21px;
                width: 356px;
                height: 45px;
                background: #3964E6;
                border-radius: 10px;
                text-align: center;
                line-height: 45px;
                font-size: 16px;
                color: #FFFFFF;

                a {
                    color: #FFFFFF;
                }
            }
        }
    }
}

.base {
    width: 1343px;
    margin: 20px auto;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .left {
        padding: 0 35px;
        width: 919px;
        min-height: 646px;
        height: 100%;
        background: #FFFFFF;
        box-sizing: border-box;

        .title {
            padding-bottom: 30px;
            width: 100%;
            border-bottom: 1px solid #D8D8D8;
            margin-top: 31px;
            font-weight: 600;
            font-size: 22px;

        }

        .list {
            width: 100%;
            border-bottom: 1px solid #D8D8D8;
            cursor: pointer;

            .headline {
                padding-top: 30px;
                font-size: 18px;
                padding-bottom: 15px;
            }

            .headline:hover {
                color: #4A69EA;
            }

            .contents {
                font-size: 16px;
                color: #999999;
                padding-bottom: 30px;
            }
        }

        .gd {
            text-align: center;
            padding: 37px;
            font-size: 16px;
            box-sizing: border-box;
            color: #4A69EA;
        }
    }

    .right {
        width: 400px;
        min-height: 646px;
        height: 100%;
        background: #FFFFFF;
        box-sizing: border-box;
        padding: 31px 35px 44px 53px;

        .ranking {
            font-weight: 600;
            font-size: 22px;
            display: flex;

            .icon {
                width: 28px;
                height: 27px;
                margin-right: 11px;
            }
        }

        .items {
            display: flex;
            padding: 27px 0;
            font-size: 16px;
            align-items: center;
            justify-content: space-between;

            .it {
                cursor: pointer;
            }

            .it:hover {
                color: #4A69EA;
            }

            .itemsActive {
                border-bottom: #3964E6 solid 2px;
            }
        }

        .schoolList {
            .its:hover {
                color: #4A69EA;
            }

            .its {
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-bottom: 12px;


                .number {
                    text-align: center;
                    line-height: 45px;
                    width: 28px;
                    height: 34px;
                    margin-right: 17px;
                    font-size: 16px;
                }

                .one {
                    background-image: url('./images/火苗@2x.png');
                    background-size: 100% 100%;
                    color: #FFFFFF;
                }

                .two {
                    background-image: url('./images/火苗备份@2x.png');
                    background-size: 100% 100%;
                    color: #FFFFFF;
                }

                .three {
                    background-image: url('./images/火苗备份 2@2x.png');
                    background-size: 100% 100%;
                    color: #FFFFFF;
                }

                .schoolName {
                    width: 60%;
                    height: 34px;
                    line-height: 45px;
                    font-size: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}
</style>