<template>
    <div class="box" style="max-width: 1200px;margin: 0 auto;">
        <div class="rank_wrap">
            <div class="left_main left_set_height">
                <div class="title" id="big_title"><span>大学排行榜</span></div>
                <div class="filter">
                    <ul class="level-one-filter">
                        <li>排名类型: </li>
                        <li :class="obj.Type == it.Id ? 'oneActive' : ''" v-for="(it, index) in RankingType" :key="index"
                            @click="clickRankingType(it.Id)">{{ it.Name
                            }}
                        </li>
                    </ul>
                    <ul class="level-two-filter" style="display: flex;">
                        <li :class="obj.SubjectType == it.Id ? 'twoActive' : ''" v-for="(it, index) in typeList"
                            :key="index" @click="clickSubjectType(it.Id)">{{
                                it.Name
                            }}</li>
                    </ul>
                </div>

                <div class="search">
                    <el-input v-model="obj.Name" placeholder="请输入院校名称" @keyup.enter.native="getList()"></el-input>
                    <button @click="getList()">查询</button>
                </div>
                <div class="school-wrap">
                    <p v-if="list.length == 0"
                        style="color: rgba(0, 0, 0, 0.45); text-align: center; padding: 16px; font-size: 18px;">
                        暂无数据
                    </p>
                    <ul style="display: block;" v-else>
                        <li v-for=" (it, idx) in list " :key="idx" @click="goSchoolDetails(it.Uid)">
                            <div class="school-sort">{{ it.Sort }}</div>
                            <div class="school-img"><img :src="it.Logo"></div>
                            <div class="school-message">
                                <p class="school-title"><span>{{ it.Name }}</span><span class="city float_l">{{ it.AreaName
                                }}</span></p>
                                <p class="tag-list">
                                    <span class="tag-item" v-if="it.Nhef">985</span>
                                    <span class="tag-item" v-if="it.Sff">211</span>
                                    <span class="tag-item" v-if="it.Syl">双一流</span>
                                    <span class="tag-item" v-if="it.Qjjh">强基计划</span>
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="pagination">
                    <!-- <el-pagination background layout="total,prev, pager, next" :total="1000">
                    </el-pagination> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GetPcUniversityRank } from "@/api/ListRanking";
export default {
    name: 'VoluntaryReportingIndex',

    data() {
        return {
            values: '',
            obj: {
                Type: 0,
                Name: '',
                SubjectType: '',
            },
            list: [],
            RankingType: [
                { Id: 0, Name: "校友会" },
                { Id: 1, Name: "武书连" },
                { Id: 2, Name: "软科" },
                { Id: 3, Name: "QS" },
                // { Id: 4, Name: "泰晤士河报" },
            ],
            typeList: [
                { Id: '', Name: "综合排序" },
                { Id: 1, Name: "理工类" },
                { Id: 2, Name: "医学类" },
                { Id: 3, Name: "军事类" },
                { Id: 4, Name: "语言类" },
                { Id: 5, Name: "师范类" },
                { Id: 6, Name: "财经类" },
                { Id: 7, Name: "政法类" },
                { Id: 8, Name: "民族类" },
                { Id: 9, Name: "农林类" },
                { Id: 10, Name: "艺术类" },
                { Id: 11, Name: "体育类" },
                { Id: 12, Name: "其他" },
            ],
        };
    },

    mounted() {
        this.getList()
    },

    methods: {
        // 跳转学校详情
        goSchoolDetails(Id) {
            this.$router.push({
                path: '/Home/SchoolDetails',
                query: { Id }
            })
        },
        clickRankingType(id) {
            this.obj.Type = id
            this.getList()

        },
        clickSubjectType(id) {
            this.obj.SubjectType = id
            this.getList()
        },
        async getList() {
            const res = await GetPcUniversityRank({
                ...this.obj
            })
            this.list = res.data.response
        }
    },
};
</script>

<style lang="less" scoped>
.rank_wrap {
    .left_main {
        .title {
            font-size: 28px;
            color: rgba(0, 0, 0, .65);
            margin-bottom: 20px;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: space-between;

        }

        .filter {
            .level-one-filter {
                display: flex;
                margin-bottom: 14px;

                li:first-child {
                    cursor: auto;
                }

                .oneActive {
                    color: #3964E6;
                }

                li {
                    font-size: 14px;
                    font-family: MicrosoftYaHei;
                    color: #000;
                    line-height: 27px;
                    margin-right: 20px;
                    box-sizing: border-box;
                    cursor: pointer;
                }
            }

            .level-two-filter {
                display: flex;
                flex-flow: row wrap;
                margin-bottom: 14px;
                border-bottom: 1px solid #3964E6;

                .twoActive {
                    background-color: #3964E6;
                    color: #fff;
                }

                li {
                    width: 60px;
                    height: 30px;
                    line-height: 30px;
                    background: rgba(42, 28, 171, 0.03);
                    text-align: center;
                    color: #3964E6;
                    font-size: 12px;
                    font-family: MicrosoftYaHeiSemibold;
                    cursor: pointer;
                    margin-right: 10px;
                }
            }
        }

        .search {
            width: 443px;
            padding-bottom: 20px;
            display: flex;

            input {
                width: 340px;
                height: 39px;
                border-radius: 6px;
                border: 1px solid #eee;
                text-indent: 1em;
            }

            button {
                width: 103px;
                height: 39px;
                background: #3964E6;
                border-radius: 0 6px 6px 0;
                border: 1px solid #3964E6;
                color: #fff;
                font-size: 14px;
                cursor: pointer;
            }
        }

        .school-wrap ul li {
            height: 115px;
            border-bottom: 1px solid #eee;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;

            .school-sort {
                font-size: 20px;
                font-weight: 900;
                width: 50px;
                text-align: left;
                background-color: #fff;
            }

            .school-img {
                width: 60px;
                height: 60px;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .school-message {
                width: 85%;

                .school-title {
                    display: flex;
                    align-items: center;

                    span:first-child {
                        font-size: 18px;
                        font-weight: 600;
                        cursor: pointer;
                    }

                    span:last-child {
                        height: 26px;
                        line-height: 24px;
                        font-size: 12px;
                        border: 1px solid #eee;
                        color: #555;
                        display: inline-block;
                        padding: 0 10px;
                        margin: 0 20px 0 15px;
                    }
                }

                .tag-list {
                    margin-top: 20px;

                    .tag-item {
                        display: inline-block;
                        color: #555;
                        font-size: 14px;
                        padding-right: 10px;
                        margin-right: 10px;
                        border-right: 1px solid #c5c5c5;
                        line-height: 15px;
                        cursor: pointer;
                    }

                    .tag-item:last-child {
                        border-right: none;
                    }
                }
            }
        }

        .pagination {
            margin: 20px 0;

            .el-pagination {
                text-align: center;
            }
        }
    }

}
</style>