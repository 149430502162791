<template>
    <div class="box" ref="container" @scroll="handleScroll($event)"
        style="display: flex;flex-direction: column; height: 92vh;   overflow-y: auto;align-items: center;margin-top: 20px;">
        <!-- 搜索 -->
        <div class="customer-college" style="max-width: 1200px;">
            <el-input size="small" v-model="listObj.Name" placeholder="搜索" @keyup.enter.native="servelist()"></el-input>
            <span @click="servelist()">查找院校</span>
        </div>
        <div class="tabs" style="max-width: 1200px;">
            <el-tabs type="card">
                <!-- <el-tab-pane> -->
                <span slot="label"><i class="el-icon-office-building"></i> 院校优先</span>
                <div class="tzy-filter-wrapper">
                    <div class="filter-list">
                        <span class="filter-list-title">
                            院校省份
                        </span>
                        <div class="filter-list-tags">
                            <span :class="it.chenck == true ? 'tag active' : 'tag'" v-for="(it, index) in regionList"
                                :key="it.Id" @click="selectRegion(it, index)">
                                {{ it.Name }}
                            </span>
                        </div>
                    </div>
                    <div class="filter-list">
                        <span class="filter-list-title">
                            院校类型
                        </span>
                        <div class="filter-list-tags">
                            <span :class="it.chenck == true ? 'tag active' : 'tag'" v-for="(it, index) in typeList"
                                :key="it.Id" @click="selectType(it, index)">
                                {{ it.Name }}
                            </span>
                        </div>
                    </div>
                    <div class="flex college-level-wrapper">
                        <div class="filter-list filter-list-college">
                            <span class="filter-list-title">
                                院校层次
                            </span>
                            <div class="filter-list-tags">
                                <span :class="it.chenck == true ? 'tag active' : 'tag'" v-for="(it, index) in gradationList"
                                    :key="it.Id" @click="selectGradation(it, index)">
                                    {{ it.Name }}
                                </span>
                            </div>
                        </div>
                        <span class="sx">|</span>
                        <div class="filter-list tzy-filter-list-college">
                            <div class="filter-list-tags">
                                <span :class="it.chenck == true ? 'tag active' : 'tag'"
                                    v-for="(it, index) in gradationList2" :key="it.Id" @click="selectGradation2(it, index)">
                                    {{ it.Name }}
                                </span>
                            </div>
                        </div>
                        <span class="sx">|</span>
                    </div>
                </div>
                <!-- </el-tab-pane> -->
                <!-- <el-tab-pane>
                    <span slot="label"><i class="el-icon-reading"></i> 专业优先</span>
                    专业优先
                </el-tab-pane> -->
            </el-tabs>
        </div>
        <div class="schoolList" style="width: 100%;display: flex;flex-direction: column; align-items: center;">
            <div style="width: 1200px;" v-if="list.length !== 0">
                <div class="schoolItem" v-for="(it, idx) in list" :key="idx" @click="goSchoolDetails(it.Id)">
                    <div class="schoolLogo">
                        <img :src="it.Logo">
                    </div>
                    <div class="schoolInfo">
                        <div class="search_title">
                            <h3 class="schoolName">
                                <em style="width: 300px;">{{ it.Name }}</em>
                                <span class="cityName">{{ it.AreaName }}</span>
                            </h3>
                        </div>
                        <div class="tags">
                            <span>{{ it.AscriptionName }}</span>
                            <span v-if="it.Nhef">985</span>
                            <span v-if="it.Sff">211</span>
                            <span v-if="it.Syl">双一流</span>
                            <span v-if="it.Qjjh">强基计划</span>
                        </div>
                        <div class="heat">
                            <span style="width: 200px;">
                                排名
                                <br>
                                第{{ it.Rank }}名
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div style="width: 1200px;" v-else>
                <el-empty description="暂无数据"></el-empty>
            </div>
            <!-- 加载 -->
            <div class="loading_wrap" v-show="isLoading">
                <div class="loading">
                    <div class="container"></div>
                </div>
                <div>正在加载中</div>
            </div>
        </div>
    </div>
</template>
  
<script>

import { GetRegionList } from "@/api/home";
import { GetUniversitys } from "@/api/SearchUniversity";
export default {
    name: 'SearchUniversityIndex',
    components: {
    },
    data() {
        return {
            isLoading: true, // 展示loading
            ZPage: 0,
            listObj: {
                Name: '',
                AreaName: [],
                Nature: [],
                Ascription: [],
                Type: [],
                PageIndex: 1,
                PageSize: 20,
            },
            list: [],
            regionArr: [],
            typeArr: [],
            gradationArr: [],
            gradationArr2: [],

            regionList: [],
            typeList: [
                { Id: -2, Code: "-2", Name: "不限", chenck: true },
                { Id: 0, Code: "0", Name: "综合", chenck: false },
                { Id: 1, Code: "1", Name: "理工类", chenck: false },
                { Id: 2, Code: "2", Name: "医学类", chenck: false },
                { Id: 3, Code: "3", Name: "军事类", chenck: false },
                { Id: 4, Code: "4", Name: "语言类", chenck: false },
                { Id: 5, Code: "5", Name: "师范类", chenck: false },
                { Id: 6, Code: "6", Name: "财经类", chenck: false },
                { Id: 7, Code: "7", Name: "政法类", chenck: false },
                { Id: 8, Code: "8", Name: "民族类", chenck: false },
                { Id: 9, Code: "9", Name: "农林类", chenck: false },
                { Id: 10, Code: "10", Name: "艺术类", chenck: false },
                { Id: 11, Code: "11", Name: "体育类", chenck: false },
                { Id: 12, Code: "12", Name: "其他", chenck: false },
            ],
            gradationList: [
                { Id: -3, Code: "-1", Name: "不限", chenck: true },
                { Id: 0, Code: "0", Name: "公办", chenck: false },
                { Id: 1, Code: "1", Name: "民办", chenck: false },
                { Id: 2, Code: "2", Name: "中外合作", chenck: false },
                { Id: 3, Code: "3", Name: "港澳台", chenck: false },
            ],
            gradationList2: [
                { Id: 1, Code: "1", Name: "教育部", chenck: false },
                { Id: 2, Code: "2", Name: "省政府", chenck: false },
                { Id: 3, Code: "3", Name: "其他部委", chenck: false },
                { Id: 4, Code: "4", Name: "军校", chenck: false },
            ],
            IsShow: true,
            isshopCar: true,
        };
    },
    scrollBottom() {
        //加载更多
        var scrollTop =
            document.documentElement.scrollTop || document.body.scrollTop;
        //变量windowHeight是可视区的高度
        var windowHeight =
            document.documentElement.clientHeight || document.body.clientHeight;
        //变量scrollHeight是滚动条的总高度
        var scrollHeight =
            document.documentElement.scrollHeight || document.body.scrollHeight;
        //滚动条到底部的条件
        if (scrollTop + windowHeight == scrollHeight && scrollTop > 100) {
            //写后台加载数据的函数
            console.log(111);
            // this.getList();
        }
    },
    mounted() {
        this.getRegionList()
        this.getList()
        window.addEventListener("scroll", this.scrollBottom);
    },
    destroyed() {
        window.removeEventListener("scroll", this.scrollBottom);
    },
    methods: {
        servelist() {
            this.list = []
            this.getList()
        },
        handleScroll(e) {
            let el = e.target
            //判断是否到达div容器底部
            if (el.scrollTop + el.clientHeight >= el.scrollHeight - 1) {
                //控制页数
                if (this.listObj.PageIndex == this.ZPage) {
                    this.isLoading = false
                    this.$message({
                        message: '没有更多了~',
                        type: 'warning'
                    });
                } else {

                    //调用后台接口
                    setTimeout(() => {
                        this.listObj.PageIndex += 1
                        this.isLoading = true
                        this.getList()
                    }, 1000)

                }

            }
        },
        // 跳转学校详情
        goSchoolDetails(Id) {
            this.$router.push({
                path: '/Home/SchoolDetails',
                query: { Id }
            })
        },
        // 获取冲稳保院校
        async getList() {
            this.isLoading = true
            const res = await GetUniversitys({
                Name: this.listObj.Name,
                AreaName: this.listObj.AreaName,
                Type: this.listObj.Type,
                Nature: this.listObj.Nature,
                Ascription: this.listObj.Ascription,
                PageIndex: this.listObj.PageIndex,
                PageSize: this.listObj.PageSize,
            })
            if (res.data.msg == '获取失败') {
                this.list = []
                this.listObj.Name = ''
                this.ZPage = 0
                this.isLoading = false
            } else {
                this.list.push(...res.data.response.data)
                this.listObj.Name = ''
                this.ZPage = res.data.response.pageCount
                this.isLoading = false
            }
        },
        // 选择院校省份
        selectRegion(it, idx) {
            this.disport(it, idx, this.regionList, this.regionArr, 0)
        },

        // 选择院校类型
        selectType(it, idx) {
            this.disport(it, idx, this.typeList, this.typeArr, 0)
        },

        // 选择院校层次1
        selectGradation(it, idx) {
            this.disport(it, idx, this.gradationList, this.gradationArr, 1)
        },

        // 选择院校层次2
        selectGradation2(it, idx) {
            this.disport(it, idx, this.gradationList2, this.gradationArr2, 1)
        },

        // 获取省份数据
        async getRegionList() {
            const res = await GetRegionList({
                code: 0
            })
            this.regionList = res.data.response
            this.regionList.forEach((item) => {
                this.$set(item, "chenck", false)
            })
            this.regionList.unshift({ Id: -1, Code: "-1", Name: "不限", chenck: true })
        },

        // 合并
        mergeARR() {
            this.listObj.AreaName = []
            this.listObj.Nature = []
            this.listObj.Type = []
            this.listObj.Ascription = []
            this.regionArr.forEach((item) => {
                this.listObj.AreaName.push(item.Name)
            })
            this.typeArr.forEach((item) => {
                this.listObj.Type.push(item.Id)
            })
            this.gradationArr.forEach((item) => {
                this.listObj.Nature.push(item.Id)
            })
            this.gradationArr2.forEach((item) => {
                this.listObj.Ascription.push(item.Id)
            })
            // this.listObj.AreaName = this.listObj.AreaName.join()
            this.list = []
            this.listObj.PageIndex = 1
            this.getList()
        },

        // 重置
        rest() {
            this.tags = []
            this.regionArr = []
            this.gradationArr = []
            this.gradationArr2 = []
            this.typeArr = []
            this.regionList.forEach((item) => {
                this.$set(item, "chenck", false)
            })
            this.typeList.forEach((item) => {
                this.$set(item, "chenck", false)
            })
            this.gradationList.forEach((item) => {
                this.$set(item, "chenck", false)
            })
            this.gradationList2.forEach((item) => {
                this.$set(item, "chenck", false)
            })
            this.regionList[0].chenck = true
            this.typeList[0].chenck = true
            this.gradationList[0].chenck = true
        },
        // 处理
        disport(it, idx, list, Arr, type) {
            if (it.Name !== '不限') {
                list[0].chenck = false
                var index = Arr.findIndex(item => item.Name == it.Name)
                // 没有就添加
                if (index == -1) {
                    Arr.push(it)
                    list[idx].chenck = true
                    this.mergeARR()
                    // 有就删除
                } else {
                    Arr.splice(index, 1)
                    list[idx].chenck = false
                    this.mergeARR()
                }
                if (type == 1) {
                    const b = this.gradationList.every(item => item.chenck == false)
                    const c = this.gradationList2.every(item => item.chenck == false)
                    if (b && c) {
                        this.gradationList[0].chenck = true
                    } else {
                        this.gradationList[0].chenck = false
                    }
                } else {
                    const b = list.some(item => item.chenck == true)
                    if (b) {
                        list[0].chenck = false
                    } else {
                        list[0].chenck = true
                    }
                }
            } else {
                if (type == 1) {
                    this.gradationArr = []
                    this.gradationArr2 = []
                    this.gradationList.forEach((item) => {
                        this.$set(item, "chenck", false)
                    })
                    this.gradationList2.forEach((item) => {
                        this.$set(item, "chenck", false)
                    })
                    this.gradationList[0].chenck = true
                    this.mergeARR()
                } else {
                    Arr.splice(0, Arr.length);//清空数组
                    list.forEach((item) => {
                        this.$set(item, "chenck", false)
                    })
                    list[0].chenck = true
                    this.mergeARR()
                }
            }
        },
    },
};
</script>
  
<style lang="less" scoped>
::-webkit-scrollbar {
    width: 0 !important;
}

::-webkit-scrollbar {
    width: 0 !important;
    height: 0;
}


// ------loading样式---------
.loading_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
    padding: 20px 0;

    .loading {
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        border: 2px solid #ddd;
        border-radius: 50%;
        animation: rotate 1s linear infinite;
        margin-right: 10px;
    }

    .container {
        position: relative;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        background-color: #fff;
    }
}

/*
------loading动画---------*/
@keyframes rotate {
    from {
        transform-origin: center center;
        transform: rotate(0deg);
    }

    to {
        transform-origin: center center;
        transform: rotate(360deg);
    }
}

img {
    width: 100%;
    height: 100%;
}

.el-select {
    display: inline-block;
    position: relative;
    width: 100%;
}

.customer-college {
    width: 1200px;
    position: fixed;
    top: 70px;
    font-size: 12px;
    color: #4a4a4a;
    letter-spacing: 0;
    line-height: 18px;
    z-index: 2;
    display: flex;
    align-items: center;
    height: 70px;
    background: #fff;

    span {
        border-radius: 6px;
        width: 103px;
        height: 39px;
        color: rgb(255, 255, 255);
        background: #3964e6;
        border: 1px solid #3964e6;
        font-size: 14px;
        cursor: pointer;
        text-align: center;
        line-height: 39px;
        margin-left: 20px;
    }
}

.tabs {
    position: relative;
    margin-top: 50px;


    ::v-deep .el-tabs__item {
        background-color: #f2f2f2;
        width: 200px;
        height: 46px;
        line-height: 46px;
        text-align: center;
        margin-right: -1px;
        border-bottom: 1px solid #e5e5e5;
    }

    ::v-deep .el-tabs__item:hover {
        color: #3964e6;
    }

    ::v-deep .is-active {
        border-bottom: 0 solid #e5e5e5;
        background-color: #fff;
        color: #3964e6;
    }

    ::v-deep .el-tabs__header {
        margin: 0;
    }

    .tzy-filter-wrapper {
        padding: 24px;
        border-right: 1px solid #e5e5e5;
        border-left: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;

        .filter-list {
            display: flex;
            align-items: flex-start;

            .filter-list-title {
                font-size: 16px;
                text-align: center;
                color: #9b9b9b;
                margin-top: 13px;
            }

            .filter-list-tags {
                margin-left: 10px;
                flex: 1;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;

                .tag {
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    font-style: normal;
                    margin: 10px;
                    padding: 2px 6px;
                    margin-right: 10px;
                    text-align: center;
                    border-radius: 4px;
                    cursor: pointer;
                    box-sizing: border-box;
                }

                .tag:hover {
                    color: #3964e6;
                }

                .active {
                    border: 1px solid #3964e6;
                    color: #3964e6;
                }
            }

        }

        .college-level-wrapper {
            display: flex;

            .sx {
                font-weight: 100;
                height: 47px;
                display: flex;
                align-items: center;
                color: #e6e6e6;
                transform: translateY(-2px);
            }

        }
    }

    .myFilterRecordBlockRow {
        overflow: hidden;
        background: #fafafa;
        border: 1px solid #e5e5e5;
        display: flex;
        height: auto;
        transition: all 2s ease-in-out;
        display: flex;
        align-items: center;

        .button-actions {
            font-size: 16px;
            color: #3964e6;
            margin-right: 20px;
            cursor: pointer;
        }

        .customer-selected-tags {
            flex: 1;
            min-height: 50px;
            padding: 6px 14px 6px 20px;
            overflow: hidden;
            display: flex;
            align-items: center;

            .title {
                font-size: 14px;
                color: #9b9b9b;
            }

            .tags {
                display: flex;
                justify-content: center;
                align-items: center;

                ::v-deep .el-tag {
                    margin-right: 10px;
                    font-size: 14px;
                }
            }
        }
    }
}

.schoolList {
    margin-top: 20px;
    //要想scroll事件生效，必须给目标div编写下面两个样式。
    height: 102%;
    position: relative;

    .schoolItem {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 20px;
        cursor: pointer;
        border-bottom: 1px solid #eee;
        position: relative;

        .schoolLogo {
            margin-right: 20px;
            width: 60px;
            height: 60px;
        }

        .schoolInfo .search_title {
            margin-bottom: 18px;

            .schoolName:hover {
                color: #3964e6;
            }

            .schoolName {
                color: #333;
                font-size: 18px;
                height: 25px;
                line-height: 25px;
                margin: 0 20px 0 0;
                transition: all .3s;
                display: flex;
                align-items: center;

                em {
                    display: inline-block;
                    max-width: 298px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .cityName {
                    height: 26px;
                    line-height: 24px;
                    font-size: 12px;
                    border: 1px solid #eee;
                    color: #555;
                    display: inline-block;
                    padding: 0 10px;
                    margin: 0 20px 0 15px;
                }
            }
        }

        .tags span {
            display: inline-block;
            color: #555;
            padding-right: 10px;
            margin-right: 10px;
            border-right: 1px solid #c5c5c5;
            line-height: 15px;
        }

        .heat {
            display: flex;
            position: absolute;
            right: 200px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            color: #666;
            font-size: 12px;
            font-family: Microsoft YaHei, SimSun, Arial, sans-serif !important;
        }
    }
}
</style>