import request from "@/utils/request";

// 获取院校库
export const OneSubmitGo = (params) => {
  return request({
    method: "get",
    url: `/api/D_PlanMajorDesc/OneSubmitGo`,
    params,
  });
};
