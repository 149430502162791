<template>
    <div class="box">
        <div class="header___iIg2m">
            <div class="header-info-wrap___xZPe1">
                <div class="header-title___Er_Ot">{{ explainObj.Name }}</div>
                <!-- <div class="header-desc___k0UEo">本科&nbsp;&nbsp;四年&nbsp;&nbsp;学位：哲学学士</div> -->
                <div class="header-cname-wrap___zvUP_">
                    <div class="header-cname___UaHvQ">{{ explainObj.Name }}类</div>
                </div>
                <!-- <div class="follow-btn___HIGfj">
                    关注</div> -->
            </div>
            <div class="bd___rtYA_">
                <div class="tab-list___oInFs">
                    <div :class="istabs == idx ? 'tab-list-item___SHCU0 active___f6N4Y' : 'tab-list-item___SHCU0'"
                        v-for="(it, idx) in tab" :key="idx" @click="qiehuan(idx)">{{ it.name }}</div>
                </div>
            </div>
        </div>
        <!--  -->
        <div class="main___U2RHU" v-show="istabs == 0">
            <div class="item">
                <div class="header___sHjUt">
                    <div class="title___SKh1G">专业介绍</div>
                    <!-- <div class="more___IxXik"><span>更多（7项）</span>
                        <i class="el-icon-arrow-right"></i>
                    </div> -->
                </div>
                <div class="area___u1O3U">
                    <!-- <div class="title___Ht68N">{{ explainObj.Name }}</div> -->
                    <div class="content____59ML">
                        {{ explainObj.abstracts }}
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="header___sHjUt">
                    <div class="title___SKh1G">主要课程</div>
                    <!-- <div class="more___IxXik"><span>更多（7项）</span>
                        <i class="el-icon-arrow-right"></i>
                    </div> -->
                </div>
                <div class="area___u1O3U">
                    <!-- <div class="title___Ht68N">{{ explainObj.Name }}</div> -->
                    <div class="content____59ML" v-if="explainObj.maincourse !== ''">
                        {{ explainObj.maincourse }}
                    </div>
                    <p v-else style="color: rgba(0, 0, 0, 0.45); text-align: center; padding: 16px; font-size: 18px;">
                        暂无数据</p>
                </div>
            </div>
            <div class="item">
                <div class="header___sHjUt">
                    <div class="title___SKh1G">考研方向</div>
                    <!-- <div class="more___IxXik"><span>更多（7项）</span>
                        <i class="el-icon-arrow-right"></i>
                    </div> -->
                </div>
                <div class="area___u1O3U">
                    <!-- <div class="title___Ht68N">{{ explainObj.Name }}</div> -->
                    <div class="content____59ML" v-if="explainObj.studydirection !== ''">
                        {{ explainObj.studydirection }}
                    </div>
                    <p v-else style="color: rgba(0, 0, 0, 0.45); text-align: center; padding: 16px; font-size: 18px;">
                        暂无数据</p>
                </div>
            </div>
            <!--  -->
            <div class="tables">
                <div class="tip___xTUU2">共找到 {{ count }} 所学校</div>
                <div style="display: flex;">
                    <div class="select" style="width: 100px;margin-right: 10px">
                        <el-select size="small" v-model="screenObj.AreaName" placeholder="所在地区"
                            @change="AreaNameChange($event)">
                            <el-option v-for="(item) in regionList" :key="item.Id" :label="item.Name" :value="item.Name">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="select" style="width: 100px;margin-right: 10px">
                        <el-select size="small" v-model="screenObj.Type" placeholder="院校类型"
                            @change="AreaNameChange($event)">
                            <el-option v-for="(item) in typeList" :key="item.Id" :label="item.Name" :value="item.Id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="select" style="width: 100px;margin-right: 10px">
                        <el-select size="small" v-model="screenObj.Nature" placeholder="办学性质"
                            @change="AreaNameChange($event)">
                            <el-option v-for="(item) in gradationList" :key="item.Id" :label="item.Name" :value="item.Id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="school-wrap">
                <ul style="display: block;" v-if="list.length !== 0">
                    <li v-for="(item, index) in list" :key="index">
                        <div class="school-img"><img :src="item.Logo"></div>
                        <div class="school-message">
                            <p class="school-title"><span>{{ item.Name }}</span><span class="city float_l">{{ item.AreaName
                            }}</span>
                            </p>
                            <p class="tag-list">
                                <span class="tag-item" v-if="item.Nhef">985</span>
                                <span class="tag-item" v-if="item.Sff">211</span>
                                <span class="tag-item" v-if="item.Syl">双一流</span>
                                <span class="tag-item" v-if="item.Qjjh">强基计划</span>
                            </p>
                        </div>
                        <div class="rankingBox">
                            <div class="ranking">排名</div>
                            <div class="grade">第{{ item.Rank }}名</div>
                        </div>
                    </li>
                </ul>
                <p v-else style=" color: rgba(0, 0, 0, 0.45); text-align: center; padding: 16px; font-size: 18px;">
                    暂无数据</p>
            </div>
        </div>
        <div class="main___U2RHU" v-show="istabs == 1">
            <div class="tables">
                <div class="tip___xTUU2">共找到 {{ count }} 所学校</div>
                <div style="display: flex;">
                    <div class="select" style="width: 100px;margin-right: 10px">
                        <el-select size="small" v-model="screenObj.AreaName" placeholder="所在地区"
                            @change="AreaNameChange($event)">
                            <el-option v-for="(item) in regionList" :key="item.Id" :label="item.Name" :value="item.Name">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="select" style="width: 100px;margin-right: 10px">
                        <el-select size="small" v-model="screenObj.Type" placeholder="院校类型"
                            @change="AreaNameChange($event)">
                            <el-option v-for="(item) in typeList" :key="item.Id" :label="item.Name" :value="item.Id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="select" style="width: 100px;margin-right: 10px">
                        <el-select size="small" v-model="screenObj.Nature" placeholder="办学性质"
                            @change="AreaNameChange($event)">
                            <el-option v-for="(item) in gradationList" :key="item.Id" :label="item.Name" :value="item.Id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="school-wrap">
                <ul style="display: block;" v-if="list.length !== 0">
                    <li v-for="(item, index) in list" :key="index">
                        <div class="school-img"><img :src="item.Logo"></div>
                        <div class="school-message">
                            <p class="school-title"><span>{{ item.Name }}</span><span class="city float_l">{{ item.AreaName
                            }}</span>
                            </p>
                            <p class="tag-list">
                                <span class="tag-item" v-if="item.Nhef">985</span>
                                <span class="tag-item" v-if="item.Sff">211</span>
                                <span class="tag-item" v-if="item.Syl">双一流</span>
                                <span class="tag-item" v-if="item.Qjjh">强基计划</span>
                            </p>
                        </div>
                        <div class="rankingBox">
                            <div class="ranking">排名</div>
                            <div class="grade">第{{ item.Rank }}名</div>
                        </div>
                    </li>
                </ul>
                <p v-else style=" color: rgba(0, 0, 0, 0.45); text-align: center; padding: 16px; font-size: 18px;">
                    暂无数据</p>
            </div>
        </div>
        <!-- <div class="main___U2RHU" v-if="istabs == 2">
            <div class="tables">
                <div class="tip___xTUU2">共找到 79 所学校</div>
                <div style="display: flex;">
                    <div class="select" style="width: 100px;margin-right: 10px">
                        <el-select size="small" v-model="value" placeholder="请选择">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="select" style="width: 100px;margin-right: 10px">
                        <el-select size="small" v-model="value" placeholder="请选择">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="select" style="width: 100px;margin-right: 10px">
                        <el-select size="small" v-model="value" placeholder="请选择">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="select" style="width: 100px;margin-right: 10px">
                        <el-select size="small" v-model="value" placeholder="请选择">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="select" style="width: 100px;margin-right: 10px">
                        <el-select size="small" v-model="value" placeholder="请选择">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="school-wrap">
                <p style="display: none; color: rgba(0, 0, 0, 0.45); text-align: center; padding: 16px; font-size: 18px;">
                    暂无数据</p>
                <ul style="display: block;">
                    <li id="scroll_1">
                        <div class="school-img"><img src="https://static-data.gaokao.cn/upload/logo/140.jpg"></div>
                        <div class="school-message">
                            <p class="school-title"><span>清华大学</span><span class="city float_l">北京市</span></p>
                            <p class="tag-list"><span class="tag-item">普通本科</span><span class="tag-item">综合类</span><span
                                    class="tag-item">公办</span><span class="tag-item">985</span><span
                                    class="tag-item">211</span><span class="tag-item">双一流</span><span
                                    class="tag-item">强基计划</span></p>
                        </div>
                        <div class="rankingBox">
                            <div class="ranking">排名</div>
                            <div class="grade">第一名/综合指数93.59</div>
                        </div>
                    </li>
                    <li id="scroll_2">
                        <div class="school-img"><img src="https://static-data.gaokao.cn/upload/logo/31.jpg"></div>
                        <div class="school-message">
                            <p class="school-title"><span>北京大学</span><span class="city float_l">北京市</span></p>
                            <p class="tag-list"><span class="tag-item">普通本科</span><span class="tag-item">综合类</span><span
                                    class="tag-item">公办</span><span class="tag-item">985</span><span
                                    class="tag-item">211</span><span class="tag-item">双一流</span><span
                                    class="tag-item">强基计划</span></p>
                        </div>
                        <div class="rankingBox">
                            <div class="ranking">排名</div>
                            <div class="grade">第一名/综合指数93.59</div>
                        </div>
                    </li>
                    <li id="scroll_3">
                        <div class="school-img"><img src="https://static-data.gaokao.cn/upload/logo/114.jpg"></div>
                        <div class="school-message">
                            <p class="school-title"><span>浙江大学</span><span class="city float_l">浙江杭州市</span></p>
                            <p class="tag-list">
                                <span class="tag-item">普通本科</span><span class="tag-item">综合类</span><span
                                    class="tag-item">公办</span><span class="tag-item">985</span><span
                                    class="tag-item">211</span><span class="tag-item">双一流</span><span
                                    class="tag-item">强基计划</span>
                            </p>
                        </div>
                        <div class="rankingBox">
                            <div class="ranking">排名</div>
                            <div class="grade">第一名/综合指数93.59</div>
                        </div>
                    </li>
                    <li id="scroll_4">
                        <div class="school-img"><img src="https://static-data.gaokao.cn/upload/logo/125.jpg"></div>
                        <div class="school-message">
                            <p class="school-title"><span>上海交通大学</span><span class="city float_l">上海市</span></p>
                            <p class="tag-list">
                                <span class="tag-item">普通本科</span><span class="tag-item">综合类</span><span
                                    class="tag-item">公办</span><span class="tag-item">985</span><span
                                    class="tag-item">211</span><span class="tag-item">双一流</span><span
                                    class="tag-item">强基计划</span>
                            </p>
                        </div>
                        <div class="rankingBox">
                            <div class="ranking">排名</div>
                            <div class="grade">第一名/综合指数93.59</div>
                        </div>
                    </li>
                    <li id="scroll_5">
                        <div class="school-img"><img src="https://static-data.gaokao.cn/upload/logo/132.jpg"></div>
                        <div class="school-message">
                            <p class="school-title"><span>复旦大学</span><span class="city float_l">上海市</span></p>
                            <p class="tag-list">
                                <span class="tag-item">普通本科</span><span class="tag-item">综合类</span><span
                                    class="tag-item">公办</span><span class="tag-item">985</span><span
                                    class="tag-item">211</span><span class="tag-item">双一流</span><span
                                    class="tag-item">强基计划</span>
                            </p>
                        </div>
                        <div class="rankingBox">
                            <div class="ranking">排名</div>
                            <div class="grade">第一名/综合指数93.59</div>
                        </div>
                    </li>
                    <li id="scroll_6">
                        <div class="school-img"><img src="https://static-data.gaokao.cn/upload/logo/111.jpg"></div>
                        <div class="school-message">
                            <p class="school-title"><span>南京大学</span><span class="city float_l">江苏南京市</span></p>
                            <p class="tag-list"><span class="tag-item">普通本科</span>
                                <span class="tag-item">综合类</span><span class="tag-item">公办</span><span
                                    class="tag-item">985</span><span class="tag-item">211</span><span
                                    class="tag-item">双一流</span><span class="tag-item">强基计划</span>
                            </p>
                        </div>
                        <div class="rankingBox">
                            <div class="ranking">排名</div>
                            <div class="grade">第一名/综合指数93.59</div>
                        </div>
                    </li>
                    <li id="scroll_7">
                        <div class="school-img"><img src="https://static-data.gaokao.cn/upload/logo/66.jpg"></div>
                        <div class="school-message">
                            <p class="school-title"><span>中国科学技术大学</span><span class="city float_l">安徽合肥市</span></p>
                            <p class="tag-list"><span class="tag-item">普通本科</span><span class="tag-item">综合类</span><span
                                    class="tag-item">公办</span><span class="tag-item">985</span><span
                                    class="tag-item">211</span><span class="tag-item">双一流</span><span
                                    class="tag-item">强基计划</span></p>
                        </div>
                        <div class="rankingBox">
                            <div class="ranking">排名</div>
                            <div class="grade">第一名/综合指数93.59</div>
                        </div>
                    </li>
                    <li id="scroll_8">
                        <div class="school-img"><img src="https://static-data.gaokao.cn/upload/logo/127.jpg"></div>
                        <div class="school-message">
                            <p class="school-title"><span>华中科技大学</span><span class="city float_l">湖北武汉市</span></p>
                            <p class="tag-list"><span class="tag-item">普通本科</span><span class="tag-item">综合类</span><span
                                    class="tag-item">公办</span><span class="tag-item">985</span><span
                                    class="tag-item">211</span><span class="tag-item">双一流</span><span
                                    class="tag-item">强基计划</span></p>
                        </div>
                        <div class="rankingBox">
                            <div class="ranking">排名</div>
                            <div class="grade">第一名/综合指数93.59</div>
                        </div>
                    </li>
                    <li id="scroll_9">
                        <div class="school-img"><img src="https://static-data.gaokao.cn/upload/logo/42.jpg"></div>
                        <div class="school-message">
                            <p class="school-title"><span>武汉大学</span><span class="city float_l">湖北武汉市</span></p>
                            <p class="tag-list"><span class="tag-item">普通本科</span><span class="tag-item">综合类</span><span
                                    class="tag-item">公办</span><span class="tag-item">985</span><span
                                    class="tag-item">211</span><span class="tag-item">双一流</span><span
                                    class="tag-item">强基计划</span></p>
                        </div>
                        <div class="rankingBox">
                            <div class="ranking">排名</div>
                            <div class="grade">第一名/综合指数93.59</div>
                        </div>
                    </li>
                    <li id="scroll_10">
                        <div class="school-img"><img src="https://static-data.gaokao.cn/upload/logo/330.jpg"></div>
                        <div class="school-message">
                            <p class="school-title"><span>西安交通大学</span><span class="city float_l">陕西西安市</span></p>
                            <p class="tag-list"><span class="tag-item">普通本科</span><span class="tag-item">综合类</span><span
                                    class="tag-item">公办</span><span class="tag-item">985</span><span
                                    class="tag-item">211</span><span class="tag-item">双一流</span><span
                                    class="tag-item">强基计划</span></p>
                        </div>
                        <div class="rankingBox">
                            <div class="ranking">排名</div>
                            <div class="grade">第一名/综合指数93.59</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div> -->
    </div>
</template>


<script>
import { GetMajorDetail, GetRelevantSchool } from "@/api/detail";
import { GetRegionList } from "@/api/home";
export default {
    name: 'VoluntaryReportingIndex',

    data() {
        return {
            screenObj: {
                MajorId: '',
                AreaName: '',
                Type: -1,
                Nature: -1,
                PageIndex: 1,
                PageSize: 999
            },
            explainObj: {},
            list: [],
            count: 0,
            regionList: [],
            typeList: [
                { Id: -1, Name: "类型不限" },
                { Id: 0, Name: "综合" },
                { Id: 1, Name: "理工类" },
                { Id: 2, Name: "医学类" },
                { Id: 3, Name: "军事类" },
                { Id: 4, Name: "语言类" },
                { Id: 5, Name: "师范类" },
                { Id: 6, Name: "财经类" },
                { Id: 7, Name: "政法类" },
                { Id: 8, Name: "民族类" },
                { Id: 9, Name: "农林类" },
                { Id: 10, Name: "艺术类" },
                { Id: 11, Name: "体育类" },
                { Id: 12, Name: "其他" },
            ],
            gradationList: [
                { Id: -1, Name: "性质不限" },
                { Id: 0, Name: "公办" },
                { Id: 1, Name: "民办" },
                { Id: 2, Name: "中外合作" },
                { Id: 3, Name: "港澳台" },
                { Id: 4, Name: "教育部" },
                { Id: 5, Name: "省政府" },
                { Id: 6, Name: "其他部委" },
                { Id: 7, Name: "军校" },
            ],
            istabs: 0,
            tab: [
                {
                    name: '专业总览',
                },
                {
                    name: '开设院校',
                },
                // {
                //     name: '毕业去向',
                // },
            ]
        };
    },

    mounted() {
        this.screenObj.MajorId = this.$route.query.id//获取其他页面跳转过来的专业id
        this.getExplainObj()
        this.getList()
    },

    methods: {
        AreaNameChange() {
            this.getList()
        },
        qiehuan(idx) {
            this.istabs = idx
        },
        async getExplainObj() {
            const res = await GetMajorDetail({
                MajorId: this.screenObj.MajorId
            })
            this.explainObj = res.data.response
            this.getRegionList()
        },
        async getList() {
            const res = await GetRelevantSchool({
                ...this.screenObj
            })
            if (res.data.response.data == null) {
                this.list = []
                this.count = 0
            } else {
                this.list = res.data.response.data
                this.count = res.data.response.dataCount
            }
        },
        // 获取省份数据
        async getRegionList() {
            const res = await GetRegionList({
                code: 0
            })
            this.regionList = res.data.response
            this.regionList.unshift({ Id: -1, Code: "-1", Name: "全国" })
        },
    },
};
</script>

<style lang="less" scoped>
* {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
}

.box {
    min-height: calc(100vh - 160px);
    width: 100vw;
    position: relative;
    min-width: 1440px;
    overflow-x: auto;

    .header___iIg2m {
        position: relative;
        height: 275px;
        background-image: url(./images/detailbg.a22aad56.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        .header-info-wrap___xZPe1 {
            position: relative;
            width: 1200px;
            padding-left: 40px;
            padding-top: 45px;
            margin: 0 auto;

            .header-title___Er_Ot {
                font-size: 36px;
                font-weight: 600;
                color: #fff;
                margin-bottom: 10px;
            }

            .header-desc___k0UEo {
                font-size: 16px;
                font-weight: 500;
                color: #fff;
                margin-bottom: 20px;
            }

            .header-cname-wrap___zvUP_ {
                display: flex;

                .header-cname___UaHvQ {
                    height: 28px;
                    line-height: 28px;
                    padding: 0 15px;
                    background: #EEEEEE;
                    border-radius: 14px;
                }
            }

            .follow-btn___HIGfj {
                width: 118px;
                height: 35px;
                background: rgba(255, 255, 255, .19);
                border-radius: 23px;
                border: 1px solid #FFFFFF;
                backdrop-filter: blur(2px);
                position: absolute;
                top: 44px;
                right: 16px;
                font-size: 14px;
                color: #fff;
                line-height: 17px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }


        }

        .bd___rtYA_ {
            width: 1200px;
            margin: 0 auto;
            position: relative;
            height: 103px;

            .tab-list___oInFs {
                display: flex;
                position: absolute;
                bottom: 0;
                left: 16px;

                .tab-list-item___SHCU0.active___f6N4Y {
                    width: 154px;
                    height: 55px;
                    background: #FFFFFF;
                    border-radius: 10px 10px 0 0;
                    color: #3964E6;
                    margin-top: 0;
                }

                .tab-list-item___SHCU0 {
                    margin-top: 10px;
                    width: 154px;
                    height: 45px;
                    background: rgba(255, 255, 255, .35);
                    border-radius: 10px 10px 0 0;
                    border: 1px solid rgba(255, 255, 255, .21);
                    backdrop-filter: blur(5px);
                    margin-right: 20px;
                    font-size: 20px;
                    font-weight: 400;
                    color: #fff;
                    line-height: 28px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    user-select: none;
                }
            }
        }
    }

    .main___U2RHU {
        position: relative;
        min-width: 1200px;
        padding-bottom: 20px;
        width: 1200px;
        margin: 0 auto;

        .item {
            .header___sHjUt {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 30px;
                margin-bottom: 20px;

                .title___SKh1G {
                    font-size: 24px;
                    font-weight: 500;
                    color: #333;
                }

                .more___IxXik {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    color: #9d9d9d;
                    cursor: pointer;
                }
            }

            .area___u1O3U {
                padding: 20px 30px;
                background-color: #fbfbfb;
                border-radius: 10px;
                border: 1px solid #EFEFEF;

                .title___Ht68N {
                    font-size: 20px;
                    font-weight: 600;
                    color: #333;
                    margin-bottom: 20px;
                }

                .content____59ML {
                    font-size: 14px;
                    font-weight: 400;
                    color: #333;
                    line-height: 24px;
                }
            }
        }

        .tables {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 50px;
            background: linear-gradient(360deg, #FFFFFF 0%, #F5F5F5 100%);
            border-radius: 10px 10px 0 0;
            padding: 0 40px;

            .tip___xTUU2 {
                font-size: 14px;
                color: #9d9d9d;
            }
        }

        .school-wrap ul li {
            height: 115px;
            border-bottom: 1px solid #eee;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;


            .school-img {
                margin-left: 50px;
                width: 60px;
                height: 60px;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .school-message {
                width: 65%;

                .school-title {
                    display: flex;
                    align-items: center;

                    span:first-child {
                        font-size: 18px;
                        font-weight: 600;
                        cursor: pointer;
                    }

                    span:last-child {
                        height: 26px;
                        line-height: 24px;
                        font-size: 12px;
                        border: 1px solid #eee;
                        color: #555;
                        display: inline-block;
                        padding: 0 10px;
                        margin: 0 20px 0 15px;
                    }
                }

                .tag-list {
                    margin-top: 20px;

                    .tag-item {
                        display: inline-block;
                        color: #555;
                        font-size: 14px;
                        padding-right: 10px;
                        margin-right: 10px;
                        border-right: 1px solid #c5c5c5;
                        line-height: 15px;
                        cursor: pointer;
                    }

                    .tag-item:last-child {
                        border-right: none;
                    }
                }
            }

            .rankingBox {
                height: 30%;
                font-size: 16px;

                .grade {
                    margin-top: 10px;
                    width: 200px;
                }
            }
        }
    }
}
</style>