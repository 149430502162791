<template>
    <div class="box" style="min-width: 1920px;">
        <div style="opacity: 0;height: 20px;">/</div>
        <div class="content">
            <div class="left">
                <div class="userInfo">
                    <div class="photo">
                        <img :src="userInfo.AvatarUrl">
                    </div>
                    <div class="phone">{{ userInfo.UserCode }}</div>
                </div>
                <div class="item" @click="go(0)">
                    <span></span>
                    <span>账号信息</span>
                </div>
                <div class="item" @click="go('zyTable')">
                    <span></span>
                    <span>我的志愿表</span>
                </div>
                <div class="item" @click="go(1)">
                    <span></span>
                    <span>我的订单</span>
                </div>
            </div>
            <div class="right">
                <!-- <div class="title">账号信息</div>
                <div class="modification">

                </div> -->
                <!-- 账号信息 -->
                <UserInfoModification v-if="IsShow == 0" />
                <MyOrder v-if="IsShow == 1" />
            </div>
        </div>
    </div>
</template>

<script>
import { GetCustomerInfoById } from "@/api/home";
import MyOrder from "./components/MyOrder"
import { getId } from "@/utils/auth.js";
import UserInfoModification from "./components/UserInfoModification"
export default {
    name: 'VoluntaryReportingIndex',
    components: { UserInfoModification, MyOrder },
    data() {
        return {
            IsShow: 1,
            userInfo: {}
        }
    },

    mounted() {
        this.getUserInfo()
    },

    methods: {
        go(url) {
            if (url == 'zyTable') {
                this.$router.push('/Home/' + url)
            } else {
                this.IsShow = url
            }
        },
        // 获取个人信息
        async getUserInfo() {
            const res = await GetCustomerInfoById(
                {
                    Id: getId(),
                }
            )
            this.userInfo = res.data.response
            // this.userInfo.UserCode = 16684000028
        },
    },
};
</script>

<style lang="less" scoped>
img {
    width: 100%;
    height: 100%;
}

.box {
    box-sizing: border-box;
    background: #F5F4F8;
}

.content {
    width: 1343px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
        width: 434px;
        height: 933px;
        background: #FFFFFF;
        box-sizing: border-box;
        padding: 50px;

        .userInfo {
            display: flex;
            align-items: center;
            margin-bottom: 75px;

            .photo {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 15px;
            }

            .phone {
                font-weight: 600;
                font-size: 32px;
                color: #333333;
            }
        }

        .item {
            cursor: pointer;
            font-size: 20px;
            font-weight: 500;
            color: #333333;
            margin-bottom: 28px;
        }
    }

    .right {
        width: 890px;
        height: 933px;
        background: #FFFFFF;
        box-sizing: border-box;
        padding: 35px 46px;
    }
}
</style>