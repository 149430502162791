<template>
    <div class="compBox">
        <div>
            <a class="image-item" v-for="it in Imglist" :key="it">
                <div v-if="it.isSHow" class="cover-image" @click="dialogVisible = true"
                    :style="{ 'background': 'url(' + require('./images/9d02bb82.png') + ')' }">
                    <div class="colleges-imgs-mask"><i class="f28 iconfont iconvr"></i></div>
                </div>
                <div v-else class="el-image cover-image">
                    <el-image :src="it" :preview-src-list="Imglist" style="width: 216px;
    height: 120px;">
                    </el-image>
                </div>
                <!-- <p class="mt15 text-overflow-ellipsis f14 lh20">{{ it.name }}</p> -->
            </a>
            <el-dialog :visible.sync="dialogVisible" width="40%" :before-close="handleClose">
                <video src="http://200000455.vod.myqcloud.com/200000455_52270db87dde11e59f7733015e052d48.f20.mp4"
                    width="100%" height="100%" autoplay="autoplay" controls="controls" :state="viewVideo"
                    ref="vueRef"></video>
            </el-dialog>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VoluntaryReportingIndex',
    props: {
        Img: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            dialogVisible: false,
            srcList: [
                'https://img1.youzy.cn/content/media/thumbs/p00065520.jpeg',
                'https://img1.youzy.cn/content/media/thumbs/p00065517.jpeg',
                'https://img1.youzy.cn/content/media/thumbs/p00065518.jpeg',
                'https://img1.youzy.cn/content/media/thumbs/p00066675.jpeg',
                'https://i6.youzy.cn/basiclib/college/images/10003-ab7c3598.jpg',
                'https://i6.youzy.cn/basiclib/college/images/10003-ab7c3598.jpg',
                'https://i6.youzy.cn/basiclib/college/images/10003-4ff1d5cd.jpg',
                'https://i6.youzy.cn/basiclib/college/images/10003-3b7321f4.jpg',
            ],
            Imglist: [],
            list: [
                {
                    name: 'VR校园',
                    isSHow: true,
                    url: 'http://toc.cn-bj.ufileos.com/media/files/20210414/9d02bb82.png',
                },
                {
                    name: '清华大学',
                    isSHow: true,
                    url: 'http://img1.youzy.cn/content/media/thumbs/p00176063.jpeg',
                },
                {
                    name: '伟伦楼',
                    isSHow: false,
                    url: 'https://img1.youzy.cn/content/media/thumbs/p00065520.jpeg',
                },
                {
                    name: '中央主楼',
                    isSHow: false,
                    url: 'https://img1.youzy.cn/content/media/thumbs/p00065517.jpeg',
                },
                {
                    name: '综合体育馆',
                    isSHow: false,
                    url: 'https://img1.youzy.cn/content/media/thumbs/p00065518.jpeg',
                },
                {
                    name: '校门',
                    isSHow: false,
                    url: 'https://img1.youzy.cn/content/media/thumbs/p00066675.jpeg',
                },
                {
                    name: '校门',
                    isSHow: false,
                    url: 'https://i6.youzy.cn/basiclib/college/images/10003-ab7c3598.jpg',
                },
                {
                    name: '图书馆',
                    isSHow: false,
                    url: 'https://i6.youzy.cn/basiclib/college/images/10003-4ff1d5cd.jpg',
                },
                {
                    name: '理学院楼',
                    isSHow: false,
                    url: 'https://i6.youzy.cn/basiclib/college/images/10003-3b7321f4.jpg',
                },
            ]
        };
    },

    mounted() {
        this.Imglist = JSON.parse(this.Img)
    },

    methods: {
        handleClose() {
            this.dialogVisible = false
        }
    },
};
</script>

<style lang="less" scoped>
::v-deep .el-image-viewer__canvas img {
    width: 0 !important;
    height: 0 !important;
}

::v-deep .el-image-viewer__img {
    width: 50%;
    height: auto;
}

::v-deep .el-image-viewer__canvas img {
    transform: scale(0.4) rotate(0deg) !important;
}

::v-deep .el-dialog__headerbtn {
    top: 12px !important;
    cursor: pointer;
}

::v-deep .el-dialog {
    background: #333 !important;
}

::v-deep .el-dialog__body {
    padding: 0 !important;
    height: 540px !important;
}

.compBox {
    margin-top: 60px;
}

.image-item:nth-of-type(5n) {
    margin-right: 0;
}

.image-item {
    width: 216px;
    margin-right: 30px;
    margin-top: 20px;
    float: left;
    color: #333;

    .el-image {
        display: inline-block;
        overflow: hidden;
    }

    .cover-image {
        width: 216px;
        height: 120px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 50%;
        border-radius: 4px;
        position: relative;
        cursor: pointer;

        .colleges-imgs-mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 216px;
            height: 120px;
            background: rgba(0, 0, 0, .5);
            color: #fff;
            text-align: center;
            line-height: 120px;
            border-radius: 4px;
            overflow: hidden;
        }
    }

    .text-overflow-ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .lh20 {
        line-height: 20px !important;
    }

    .f14 {
        font-size: 14px !important;
    }

    .mt15 {
        margin-top: 15px !important;
    }
}

a {
    cursor: pointer;
}
</style>