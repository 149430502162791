<template>
  <div class="box">
    <div class="content">
      <div class="left">
        <div class="logo"><img src="./images/logo@2x.png" alt=""></div>
        <div class="logoText">六纬志愿专注于高考志愿填报的大数据平台，为高考考生提供在线的高考志愿填报、综合评价、港澳台留学、出国留学等升学规划服务。</div>
      </div>
      <div class="right">
        <div class="phone">
          <div class="Xcode">
            <img src="./images/output.png" alt="">
          </div>
          <div class="consultingHotline">
            <div style="margin-bottom:8px;">电话：400-621-1003</div>
            <div style="margin-bottom:8px;">扫描此二维码进行咨询</div>
            <div>咨询时间：周一至周日 9:00-18:00</div>
          </div>
        </div>
        <div class="OfficialAccounts">
          <div class="item">
            <div class="Xcode">
              <img src="./images/3d27659c05eaab620e2f90b419cdb32.jpg" alt="">
            </div>
            <div class="text">六纬志愿公众号</div>
          </div>
          <div class="item">
            <div class="Xcode">
              <img src="./images/5.jpg" alt="">
            </div>
            <div class="text">六纬志愿小程序</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VoluntaryReportingIndex',

  data() {
    return {

    };
  },

  mounted() {

  },

  methods: {

  },
};
</script>

<style lang="less" scoped>
img {
  width: 100%;
  height: 100%;
}

.box {
  width: 100%;
  height: 283px;
  box-sizing: border-box;
  background: #424040 !important;

  .content {
    color: #FFFFFF;
    margin: 0 auto;
    width: 1343px;
    padding-top: 66px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      font-size: 18px;

      .logo {
        width: 154px;
        height: 37px;
        margin-bottom: 24px;
      }

      .logoText {
        width: 400px;
      }
    }

    .right {
      display: flex;

      .phone {
        display: flex;
        align-items: end;

        .Xcode {
          width: 108px;
          height: 108px;
          margin-right: 24px;
        }

        .consultingHotline {
          margin-right: 26px;
          font-size: 18px;
        }
      }

      .OfficialAccounts {
        display: flex;

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-right: 33px;

          .Xcode {
            width: 75px;
            height: 72px;
            background: pink;
          }

          .text {
            margin-top: 11px;
            font-size: 18px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>