<template>
    <div class="compBox">
        <div class="scoreline-bar">
            <div :class="show == idx ? 'major-scoreline-btn active' : 'major-scoreline-btn'" @click="isShowAcitve(idx)"
                v-for="(it, idx) in li" :key="it">{{ it.name }}</div>
        </div>
        <div class="isSHow" v-if="show == 0">
            <div class="tabels">
                <el-select v-model="value" size="small" style="width: 100px;margin-bottom: 10px;" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <div class="tab">
                    <el-table border :data="list" stripe style="width: 100%">
                        <el-table-column prop="Years" align="center" label="年份" width="100">
                        </el-table-column>
                        <el-table-column prop="RecruitType" align="center" label="院校(招生方向)">
                        </el-table-column>
                        <el-table-column prop="BatchName" align="center" label="批次/段" width="120">
                        </el-table-column>
                        <el-table-column align="center" label="选考要求" width="100">
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.childName == undefined ? '-' : '-' }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="录取数" width="100">
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.childName == undefined ? '-' : '-' }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="最高分" width="100">
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.childName == undefined ? '-' : '-' }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="LowScore" align="center" label="最低分" width="100">
                        </el-table-column>
                        <el-table-column align="center" label="平均分" width="100">
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.childName == undefined ? '-' : '-' }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="LowScoreRank" align="center" label="最低位次" width="100">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <!-- <div class="tabels">
                <el-select v-model="value1" size="small" style="width: 100px;margin-bottom: 10px;" placeholder="请选择">
                    <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <div class="tab">
                    <el-table border :data="tableData" stripe style="width: 100%">
                        <el-table-column prop="nian" label="年份" width="100">
                        </el-table-column>
                        <el-table-column prop="name" label="院校(招生方向)">
                        </el-table-column>
                        <el-table-column prop="str" label="科类" width="100">
                        </el-table-column>
                        <el-table-column prop="str1" label="批次/段" width="100">
                        </el-table-column>
                        <el-table-column prop="str2" label="录取数" width="100">
                        </el-table-column>
                        <el-table-column prop="str3" label="最高分" width="100">
                        </el-table-column>
                        <el-table-column prop="str4" label="最低分" width="100">
                        </el-table-column>
                        <el-table-column prop="str5" label="平均分" width="100">
                        </el-table-column>
                        <el-table-column prop="str6" label="最低位次" width="100">
                        </el-table-column>
                    </el-table>
                </div>
            </div> -->
        </div>
        <div class="isSHow" v-else>
            <div class="tabels" v-if="!isVipSearchShow">
                <div class="compBox" style="margin-top: 0;">
                    <div class="table" v-loading="loading">
                        <div class="selectBox">
                            <!-- <el-select size="small" style="width: 100px;margin-right: 5px;" v-model="value11"
                                placeholder="请选择">
                                <el-option v-for="item in options11" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                            <el-select size="small" style="width: 100px;margin-right: 5px;" v-model="value111"
                                placeholder="请选择">
                                <el-option v-for="item in options111" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select> -->
                            <!-- <el-select size="small" style="width: 250px;margin-right: 5px;" v-model="value22"
                                placeholder="请选择">
                                <el-option v-for="item in options22" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select> -->
                        </div>
                        <div style="height: 31px;">
                            <div class="pl20 dir-td f14 fw600">
                                招生方向：{{ name }}({{ code }})
                            </div>
                        </div>
                        <div style="height: 36px;">
                            <table class="my-table head" style="top: 154px; width: 100%;">
                                <thead class="my-table-head fw400">
                                    <th width="100">年份</th>
                                    <th class="text-left pl20">专业名称</th>
                                    <th width="100">选考要求</th>
                                    <th width="100">录取数</th>
                                    <th width="130">最高分</th>
                                    <th width="80">最低分</th>
                                    <th width="80">平均分</th>
                                    <th width="80">最低位次</th>
                                </thead>
                            </table>
                        </div>
                        <div class="tab">
                            <table class="my-table body">
                                <tbody class="my-table-body">
                                    <tr v-for="(it, index) in list2" :key="index">
                                        <td width="100" class="text-center"><span class="cntext">{{ it.Years }}</span></td>
                                        <td class="pt20 pb20 pl20 ">
                                            <a href="http://zy.ycymedu.com/majors/small?code=140101" target="_blank"
                                                class="flex-column text-color-title">
                                                <span class="cntext fw600 f14">{{ it.Major }}</span>
                                                <span class="cntext  f12 text-999 mt5">{{ it.Remark == null ? '-' :
                                                    it.Remark
                                                }}</span>
                                            </a>
                                        </td>
                                        <td width="100" class="text-center"><span class="cntext">{{ it.SelectSubject
                                        }}</span></td>
                                        <td width="100" class="text-center"><span class="cntext">-</span></td>
                                        <td width="130" class="text-center"><span class="cntext">{{ it.HighScore }}</span>
                                        </td>
                                        <td width="80" class="text-center"><span class="cntext">{{ it.LowScore }}</span>
                                        </td>
                                        <td width="80" class="text-center"><span class="cntext">{{ it.AvgScore }}</span>
                                        </td>
                                        <td width="80" class="text-center"><span class="cntext">{{ it.LowScoreRank }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bb" v-if="isVipSearchShow">
                <img src="@/assets/images/zwt.png" alt="">
                <button @click="goOpenVip()">立即开通</button>
            </div>
        </div>
    </div>
</template>

<script>
import { GetQualificationLine, GetPlanMajorScoreLinee } from "@/api/schoolDetails";
import { getVipCheckRoles } from "@/api/vip";
// import { getId } from "@/utils/auth.js";
export default {
    name: 'VoluntaryReportingIndex',
    components: {
    },
    props: {
        schoolId: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        code: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            loading: false,
            options11: [{
                value: '2023',
                label: '2023'
            }, {
                value: '2022',
                label: '2022'
            }, {
                value: '2021',
                label: '2021'
            }],
            value11: '2023',
            options111: [{
                value: '1',
                label: '一段'
            }, {
                value: '2',
                label: '二段'
            }],
            value111: '1',
            options22: [{
                value: '001',
                label: '清华大学'
            }],
            value22: '001',


            show: 0,
            li: [
                {
                    name: '院校分数线'
                },
                {
                    name: '专业分数线'
                },
            ],
            options: [{
                value: '1',
                label: '综合'
            }],
            value: '1',
            options1: [{
                value: '1',
                label: '理科'
            },
            {
                value: '2',
                label: '文科'
            }],
            value1: '1',
            list: [],
            list2: [],
            tableData: [
                {
                    nian: 2022,
                    name: '清华大学（A003）（（北京公办））',
                    str: '1段',
                    str1: '一',
                    str2: '73',
                    str3: '一',
                    str4: '672',
                    str5: '一',
                    str6: '155',
                },
                {
                    nian: 2021,
                    name: '清华大学（A003）（（北京公办））',
                    str: '1段',
                    str1: '一',
                    str2: '73',
                    str3: '一',
                    str4: '672',
                    str5: '一',
                    str6: '155',
                },
                {
                    nian: 2020,
                    name: '清华大学（A003）（（北京公办））',
                    str: '1段',
                    str1: '一',
                    str2: '73',
                    str3: '一',
                    str4: '672',
                    str5: '一',
                    str6: '155',
                },
            ],
            SearchCount: 0,
            isVipSearchShow: false,
        };
    },

    mounted() {
        this.getList()
        this.getVIP()
    },

    methods: {
        // 获取会员权限
        async getVIP() {
            const res = await getVipCheckRoles({
                CustomerId: this.$store.state.user.studentInfo.Id
            })
            console.log('会员权限', res.data.pcRole);
            this.SearchCount = res.data.pcRole.SearchCount
            // this.SearchCount = 0
            if (this.SearchCount !== 0) {
                this.isVipSearchShow = false
                this.getList2()
            } else {
                this.isVipSearchShow = true
            }
        },
        goOpenVip() {
            this.$router.push('/Home/VipParticulars')
        },
        isShowAcitve(idex) {
            this.show = idex
        },
        async getList() {
            const res = await GetQualificationLine(
                {
                    Uid: this.schoolId,
                    Year: '',
                    BatchName: '',
                }
            )
            console.log(res);
            this.list = res.data.response.sort((a, b) => b.Years - a.Years);
        },
        async getList2() {
            const res = await GetPlanMajorScoreLinee(
                {
                    Uid: this.schoolId,
                    Year: '',
                    BatchName: '',
                }
            )
            console.log(res);
            this.list2 = res.data.response
        },
    },

};
</script>

<style lang="less" scoped>
img {
    width: 100%;
    height: 100%;
}

.bb {
    position: relative;
    width: 100%;

    button {
        position: absolute;
        top: 153px;
        left: 50%;
        transform: translateX(-50%);
        width: 142px;
        height: 49px;
        background: #28C445;
        border-radius: 10px;
        border: none;
        font-size: 24px;
        font-weight: 500;
        line-height: 49px;
        color: #FFFFFF;
        text-align: center;
        cursor: pointer;
    }
}

.compBox {
    margin-top: 60px;
}

.tabels {
    margin-bottom: 20px;
}

.scoreline-bar {
    width: 100%;
    display: flex;
    margin-bottom: 20px;

    .major-scoreline-btn {
        font-size: 18px;
        margin-right: 10px;
        cursor: pointer;
    }

    .active {
        color: #3964E6;
    }
}

.compBox {
    margin-top: 60px;

    .table {
        .tit {
            font-weight: bold;
            font-size: 28px;
            margin-bottom: 30px;
        }

        .selectBox {
            margin-bottom: 10px;
        }

        .dir-td {
            padding: 5px 12px;
            background: #fafafa;
            line-height: 20px;
            color: #1d2129;
            border: 1px solid #e8e8e8;
            border-bottom: none;
            font-size: 14px;
            padding-left: 20px;
            font-weight: bold;
        }

        .my-table {
            background-color: #fff;
            border-bottom: 1px dashed #e9e9e9;
            border-right: 1px solid #e8e8e8;
            border-top: 1px solid #e8e8e8;
            border-collapse: collapse;
            border-spacing: 0;

            .my-table-head th:first-child {
                border-left: 1px solid #e8e8e8;
            }

            .my-table-head th {
                border-bottom: 1px solid #e8e8e8;
                padding: 9px 0;
                font-size: 12px;
                color: #86909c;
            }

            .text-left {
                text-align: left !important;
            }

            .pl20 {
                padding-left: 20px !important;
            }
        }

        .tab {
            .my-table.body {
                border-top: none;
                width: 100%;

                tr.stripe {
                    background: #f7f7f7;

                }

                .text-color-title {
                    color: #1d2129;
                }

                .text-center {
                    text-align: center !important;
                }

                td {
                    font-size: 12px !important;
                    border-left: 1px solid #e8e8e8;
                    color: #333;
                    border-bottom: 1px solid #e8e8e8;
                }

                .pl20 {
                    padding-left: 20px !important;
                }

                .pb20 {
                    padding-bottom: 20px !important;
                }

                .pt20 {
                    padding-top: 20px !important;
                }

                .flex-column {
                    display: flex;
                    text-decoration: none;
                    flex-direction: column;

                    .fw600 {
                        font-weight: 600 !important;
                    }

                    .f14 {
                        font-size: 14px !important;
                    }

                    .text-999 {
                        color: #999 !important;
                    }

                    .f12 {
                        font-size: 12px !important;
                    }

                    .mt5 {
                        margin-top: 5px !important;
                    }

                    .cntext {
                        font-family: cntext !important;
                    }
                }
            }
        }
    }
}
</style>