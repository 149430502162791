import request from "@/utils/request";

// 所有新闻列表带分页
export const GetPageNews = (params) => {
  return request({
    method: "get",
    url: `/api/front/NewsInfo/GetPageNews`,
    params,
  });
};

// 根据Id 获取新闻详情
export const GetDetail = (params) => {
  return request({
    method: "get",
    url: `/api/front/NewsInfo/GetDetail`,
    params,
  });
};

// 获取新闻分类
export const GETNewsCategory = (params) => {
  return request({
    method: "get",
    url: `/api/front/NewsInfo/GETNewsCategory `,
    params,
  });
};
