import request from "@/utils/request";

// 回跳参数内容时将参数给后端
export const Callback = (params) => {
  return request({
    method: "get",
    url: `/api/oauth/callback`,
    params,
  });
};

// 根据id获取个人信息
export const GetCustomerInfoById = (params) => {
  return request({
    method: "get",
    url: `/api/front/Customer/GetCustomerInfoById`,
    params,
  });
};

// 获取首页省份选科列表
export const GetSeVolunteerInitialization = () => {
  return request({
    method: "get",
    url: `/api/SeVolunteerInitialization/Get`,
    params: {},
  });
};

// 修改个人信息
export const UpdateCustomer = (data) => {
  return request({
    method: "post",
    url: `/api/front/Customer/UpdateCustomer`,
    data,
  });
};

// 获取省市区
export const GetRegionList = (params) => {
  return request({
    method: "get",
    url: `/api/SysRegion/GetRegionList`,
    params,
  });
};

// AI智能填报 (Auth)
export const AIGo = (params) => {
  return request({
    method: "get",
    url: `/api/D_PlanMajorDesc/AIGo`,
    params,
  });
};
// 修改用户头像与名称
export const UpdateCustomerAvatarUrl = (data) => {
  return request({
    method: "post",
    url: `/api/front/Customer/UpdateCustomerAvatarUrl`,
    data,
  });
};
// 上传图片
export const UploadImg = (data) => {
  return request({
    method: "post",
    url: `/api/Img/UploadImg`,
    data,
  });
};
