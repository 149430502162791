import request from "@/utils/request";

// 查询vip权益
export const getVipCheckRoles = (params) => {
  return request({
    method: "get",
    url: `/api/back/VipCard/CheckRoles`,
    params,
  });
};
// 查询vip权益
export const CheckRoles = (params) => {
  return request({
    method: "get",
    responseType: "arraybuffer",
    url: `/payqrcode/create`,
    params,
  });
};

// 获取订单列表
export const GetFrontOrderInfoList = (params) => {
  return request({
    method: "get",
    url: `/api/back/OrderInfo/GetFrontOrderInfoList`,
    params,
  });
};
// 注销
export const AccountCancel = (data) => {
  return request({
    method: "post",
    url: `/api/front/Customer/AccountCancel`,
    data,
  });
};
