// import router from "@/router/index";
import axios from "axios";
// import store from "@/store";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { getToken, removeToken } from "@/utils/auth.js";
import { Message } from "element-ui";

const request = axios.create({
  // 1.复用请求地址前缀
  // baseURL: "https://zytb.ycymedu.com/api/",
  // baseURL: process.env.VUE_APP_BASE_API,
  baseURL: process.env.VUE_APP_BASE_API,
  // 2.设置请求头
  // headers: {
  //   "Content-Type": "application/json",
  // },
  // 3.设置请求超时时间
  // timeout: 5000,
  // 4.设置请求携带cookie
  // withCredentials: true,
  // 5.设置请求数据类型",
  // 设置请求超时时间
  timeout: 5000,
  // 公共请求头
  headers: {},
});

// 请求拦截器
request.interceptors.request.use(
  // 成功
  (config) => {
    // 进度条展示
    NProgress.start();
    // (设置)复用公共请求参数
    // config就是请求的配置对象
    // config.params.xxx = xxx
    // config.data.xxx = xxx
    // config.headers.xxx = xxx
    const token = getToken();
    // 有token就带上
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  // 失败
  (error) => {
    console.log("___1", error);
  }
);

// 响应拦截器
request.interceptors.response.use(
  (response) => {
    // 进度条结束
    NProgress.done();
    // if (response.data.code === 401) {
    // 功能失败
    // store.dispatch("user/logout");
    // 回去login·
    // router.push("/login");
    // return Promise.reject(response.data.message, 111);
    // }
    // if (response.data.code === 400) {
    //   // 功能失败
    //   store.dispatch("user/logout");
    //   // 回去login·
    //   router.push("/login");
    //   return Promise.reject(response.data.message);
    // }
    if (response.data.success) {
      return response;
    } else {
      // 如果success为false 业务出错,直接触发reject
      // 被catch分支捕获
      // Promise.reject() // 生成一个失败状态的Promise
      return response;
    }
  },
  // 请求失败 - 失败
  async function (error) {
    // 如果发生了错误，判断是否是401
    if (error.response.status === 401) {
      console.log("响应拦截器-错误", error.response.data.msg);
      Message({
        message: error.response.data.msg,
        type: "error",
        showClose: true,
      });
      removeToken();
      setTimeout(() => {
        window.location.href = process.env.VUE_APP_SSO_URL;
      }, 3000);
      // const refreshToken = store.state.tokenInfo.refresh_token;
      // if (有refresh_token) {       ---- 有refresh_token
      // if (refreshToken) {
      //   // 1. 请求新token
      //   try {
      //     const res = await axios({
      //       url: "http://localhost:8000/v1_0/authorizations",
      //       method: "PUT",
      //       headers: {
      //         Authorization: `Bearer ${refreshToken}`,
      //       },
      //     });
      //     console.log("请求新token", res.data.data.token);
      //     // 2. 保存到vuex
      //     store.commit("mSetToken", {
      //       // mSetToken是前面定义的mutations名字
      //       refresh_token: refreshToken,
      //       token: res.data.data.token,
      //     });
      //     // 3. 重发请求
      //     //    request是上面创建的axios的实例，它会自动从vuex取出token带上
      //     return request(error.config);
      //   } catch (error) {
      //     // 1. 清除token
      //     store.commit("mSetToken", {});
      //     // 2. 去到登录页(如果有token值，就不能到login)
      //     // const backtoUrl = encodeURIComponent(router.currentRoute.fullPath);
      //     // router.push("/login?backto=" + backtoUrl);
      //     return Promise.reject(error);
      //   }
      // } else {
      //   // 如果没有refresh_token的时候   ----没有refresh_token
      //   // 1.去到登录页
      //   // 2.清除token
      //   // store.commit("mSetToken", {});
      //   // const backtoUrl = encodeURIComponent(router.currentRoute.fullPath); // 回到原来跳过来的的页面,不加?后面的一串就会到首页
      //   // router.push("/login?backto=" + backtoUrl);
      return Promise.reject(error); // 返回错误信息
      // }
    } else if (error.response.status === 500) {
      Message({
        message: "出错了~",
        type: "error",
        showClose: true,
      });
      // setTimeout(() => {
      //   window.location.href = process.env.VUE_APP_SSO_URL;
      // }, 2000);
      // removeToken();
    } else {
      NProgress.done();
      return Promise.reject(error);
    }
    // 进度条结束
    // return Promise.reject(error);
  }
);

export default request;
