<template>
    <div class="box">
        <div class="list">
            <div class="treeBox">
                <div class="title">全部职业</div>
                <div class="trees">
                    <el-tree ref="tree" :default-expanded-keys="[803]" node-key="Id" :data="list" :props="defaultProps"
                        accordion @node-click="handleNodeClick">
                    </el-tree>
                </div>
            </div>
            <div class="content">
                <div class="title">{{ obj.Name }}</div>
                <div class="tagBox">
                    <div class="left">职业定义</div>
                    <div class="right">数据待更新</div>
                </div>
                <div class="tagBox">
                    <div class="left">职业其他名称</div>
                    <div class="right">数据待更新</div>
                </div>
                <div class="tagBox">
                    <div class="left">职业定义</div>
                    <div class="right">数据待更新</div>
                </div>
                <div class="tabs">
                    <div :class="name == it ? 'tab active' : 'tab'" v-for="it in tabList" :key="it" @click="goTab(it)">{{ it
                    }}
                    </div>
                </div>
                <div class="setting" v-if="name == '知识背景'">
                    <div class="tag">
                        <div class="left"><span>学历要求</span></div>
                        <div class="right">数据待更新</div>
                    </div>
                    <div class="tag">
                        <div class="left"><span>专业要求</span></div>
                        <div class="right">数据待更新</div>
                    </div>
                    <div class="tag">
                        <div class="left"><span>行业知识背景</span></div>
                        <div class="right">数据待更新</div>
                    </div>
                    <div class="tag">
                        <div class="left"><span>专业知识背景</span></div>
                        <div class="right">数据待更新</div>
                    </div>
                    <div class="tag">
                        <div class="left"><span>职业介绍</span></div>
                        <div class="right">{{ rightObj.OccupationIntraduce }}</div>
                    </div>
                    <div class="develop">职业发展</div>
                    <div class="tag">
                        <div class="left"><span>行业发展前景</span></div>
                        <div class="right">数据待更新</div>
                    </div>
                    <div class="tag">
                        <div class="left"><span>职业就业趋势</span></div>
                        <div class="right">
                            <p> 数据待更新</p>
                        </div>
                    </div>
                    <div class="tag">
                        <div class="left"><span>个人发展路径</span></div>
                        <div class="right">
                            数据待更新
                        </div>
                    </div>
                </div>
                <div class="work" v-else>
                    <div class="item" v-for="it in 6" :key="it">
                        <div class="title">java后端开发工程师</div>
                        <div class="requested">
                            <div class="left">
                                <div class="tag">1-3年</div>
                                <div class="tag">本科</div>
                                <div class="tag">vue</div>
                                <div class="tag">react</div>
                                <div class="tag">webpack</div>
                            </div>
                            <div class="right" style="color: #C93838;">
                                10-15k/月
                            </div>
                        </div>
                        <div class="message">
                            <div class="left">浙江天猫技术有限公司</div>
                            <div class="right">来源：Boss直聘</div>
                        </div>
                        <div class="message">
                            <div class="left">浙江｜0-20人｜未融资</div>
                            <div class="right">发布时间：2023-10-24</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PcGetOccupationInfo, GetRecommendIntroduce } from "@/api/Occupation";
export default {
    name: 'VoluntaryReportingIndex',

    data() {
        return {
            list: [],
            obj: {},
            rightObj: {},
            name: '知识背景',
            defaultProps: {
                children: 'children',
                label: 'Name'
            },
            tabList: [
                '知识背景', '企业招聘'
            ]
        };
    },
    mounted() {
        this.getleftList()
    },
    methods: {
        goTab(it) {
            if (it == '企业招聘') {
                this.$message.warning('数据待更新')
            } else {
                this.name = it
            }
        },
        handleNodeClick(item) {
            if (item.RootId !== 0 && item.MajorNum == 0) {
                this.obj = item
                this.getRecommend()
            }
        },
        async getleftList() {
            const res = await PcGetOccupationInfo()
            this.list = res.data.response
            setTimeout(() => {
                this.$refs.tree.setCurrentKey(this.list[0].children[0].children[0].Id)
            }, 200)
            this.obj = this.list[0].children[0].children[0]
            this.getRecommend()
        },
        async getRecommend() {
            const res = await GetRecommendIntroduce({
                Id: this.obj.Id
            })
            this.rightObj = res.data.response
        }
    },
};
</script>

<style lang="less" scoped>
::v-deep .el-tree-node.is-current>.el-tree-node__content {
    background: rgba(22, 119, 255, 0.1);
    border-right: 3px solid #1677ff;
    color: #187aff;

    ::v-deep .el-tree-node__expand-icon {
        color: rgb(0, 112, 255);
    }

    ::v-deep .is-leaf {
        color: rgba(0, 0, 0, 0);
    }
}

img {
    width: 100%;
    height: 100%;
}

.box {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #F5F4F8;
    font-family: PingFangSC-Regular, PingFang SC;
}


.list {
    margin-top: 20px;
    width: 1343px;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .treeBox {
        .title {
            font-size: 18px;
            box-sizing: border-box;
            padding: 20px;
            height: 60px;
            width: 250px;
            background: #fff;
            border: solid 1px #eceaea;
        }

        .trees {
            width: 250px;
            background: #fff;
            border: solid 1px #eceaea;
            padding: 20px;
            box-sizing: border-box;
            overflow: auto;
            height: calc(88vh - 59px);

            ::v-deep .el-tree-node__label {
                font-size: 16px !important;
            }
        }
    }


    .content {
        flex: 1;
        height: 88vh;
        overflow: auto;
        background: #fff;
        padding: 24px 51px 24px 80px;
        box-sizing: border-box;

        .title {
            font-size: 36px;
        }

        .tagBox {
            margin-top: 20px;
            display: flex;
            align-items: center;
            font-size: 14px;

            .left {
                width: 84px;
                font-weight: bold;
                margin-right: 15px;
            }

        }

        .tabs {
            margin-top: 44px;
            display: flex;
            margin-bottom: 23px;

            .tab {
                cursor: pointer;
                width: 100px;
                height: 40px;
                background: #FFFFFF;
                box-shadow: 0px 2px 4px 0px rgba(57, 100, 230, 0.2);
                border-radius: 10px;
                color: #3964E6;
                text-align: center;
                line-height: 40px;
                font-size: 16px;
                margin-right: 20px;
            }

            .active {
                color: #FFFFFF !important;
                background: #3964E6 !important;
            }
        }

        .setting {
            .tag {
                margin-bottom: 11px;
                display: flex;
                font-size: 14px;

                .left {
                    min-width: 98px;
                    margin-right: 15px;

                    span {
                        padding: 2px 7px;
                        height: 25px;
                        background: #ECF4FF;
                        border-radius: 5px;
                        text-align: center;
                        line-height: 25px;
                    }
                }


            }

            .develop {
                font-weight: bold;
                font-size: 20px;
                height: 28px;
                margin-bottom: 14px;
                margin-top: 22px;
            }
        }

        .work {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .item {
                padding: 12px 25px;
                box-sizing: border-box;
                width: 460px;
                height: 157px;
                background: #FAFBFF;
                border-radius: 10px;
                margin-bottom: 30px;

                .title {
                    font-weight: 600;
                    color: #333333;
                    font-size: 18px;
                    margin-bottom: 14px;
                }

                .requested {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 14px;
                    margin-bottom: 14px;

                    .left {
                        display: flex;

                        .tag {
                            padding: 2px 7px;
                            height: 25px;
                            background: #ECF4FF;
                            border-radius: 5px;
                            text-align: center;
                            line-height: 25px;
                            margin-right: 10px;
                        }
                    }
                }

                .message {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 14px;
                    margin-top: 8px;
                }
            }
        }
    }
}
</style>