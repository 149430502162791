<template>
  <div id="app">
    <div class="SwiperBox" @mouseenter.stop="MouseFun('移入')" @mouseleave.stop="MouseFun('移出')">
      <!-- 图片 -->
      <img @click="onClickImg()" :class="['imgCss', ShowImg == index ? 'ShowCss' : '']" :src="item.imgUrl"
        v-for="(item, index) in imgList" :key="index" />
      <!-- 左箭头按钮 -->
      <!-- <div class="leftBtn" @click="throttle(PrevFun)">&larr;</div> -->
      <!-- 右箭头按钮 -->
      <!-- <div class="rightBtn" @click="throttle(NextFun)">&rarr;</div> -->
      <!-- 下方指示点容器 -->
      <div class="instBox">
        <div v-for="(item, index) in imgList.length" :key="index" @mouseover="ShowImg = index"
          :class="['inst', ShowImg == index ? 'instActv' : '']"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OCRrecognitionIndex',
  components: {},
  props: {
    shower: Number
  },
  data() {
    return {
      imgList: [
        { imgUrl: require("../../assets/images/swipers.png"), path: '/Home/Homepage' },
        { imgUrl: require("../../assets/images/编组@2x (1).png"), path: '/Home/Homepage' },
        { imgUrl: require("../../assets/images/编组备份 2@2x.png"), path: '/Home/Homepage' },
        { imgUrl: require("../../assets/images/编组备份 4@2x.png"), path: '/Home/Homepage' },

      ],
      ShowImg: 0,  // 表示当前显示的图片
      isShow: false,
      flag: true, // 用来节流防止重复点击
      start: null, // 自动执行下一张定时器
    }
  },
  watch: {
    shower(newVa) {
      this.ShowImg = newVa
      if (this.ShowImg == 1) {
        this.isShow = true
      } else if (this.ShowImg == 6) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    }
  },
  mounted() {
    this.setTimeoFun()
  },
  methods: {
    // 跳转外部链接
    go() {
      if (this.ShowImg == 1) {
        this.isShow = true
        window.open('http://sy.ycymedu.com/', '_blank')
      } else if (this.ShowImg == 6) {
        this.isShow = true
        window.open('http://sports.ycymedu.com/#/login', '_blank')
      } else {
        this.isShow = false
      }
    },
    onClickImg() {
      // console.log(this.ShowImg);
      // this.$router.push(this.imgList[this.ShowImg].path)
    },
    // 这里定义一个鼠标移入移出事件，鼠标悬停时停止自动轮播，鼠标移出则重新执行自动轮播
    MouseFun(type) {// 停止定时器            // 重新执行定时器
      type == '移入' ? clearTimeout(this.start) : this.setTimeoFun()
    },
    setTimeoFun() {
      this.start = setInterval(() => {
        this.NextFun()
      }, 4000)
    },
    // 这里通过额外封装的节流函数触发 PrevFun() 和 NextFun(),以达到防止重复点击的效果
    throttle(fun) {
      if (this.flag) {
        this.flag = false
        fun() // 此为模板中传递进来的PrevFun()或NextFun()函数
        setTimeout(() => {
          this.flag = true
        }, 200) // 节流间隔时间
      }
    },
    // 上一张
    PrevFun() {
      if (this.ShowImg !== 0) {
        this.ShowImg--
      } else {
        this.ShowImg = this.imgList.length - 1
      }
    },
    // 下一张
    NextFun() {
      if (this.ShowImg !== this.imgList.length - 1) {
        this.ShowImg++
      } else {
        this.ShowImg = 0
      }
    },
  }
};
</script>
<style lang="less" scoped>
/* 图片容器样式 */
.SwiperBox {
  position: relative;
  width: 100%;
  height: 400px;
  // min-width: 1920px;
  box-sizing: border-box;
}

.goLj {
  position: absolute;
  left: 18.8%;
  bottom: 165px;
  cursor: pointer;

  button {
    cursor: pointer;
    width: 170px;
    height: 60px;
    background: #0B0756;
    border-radius: 10px;
    text-align: center;
    line-height: 60px;
    color: #fff;
    font-size: 28px;
  }
}

/* 图片默认样式 */
.imgCss {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.8s;
  /* 淡入淡出过渡时间 */
}

/* 图片选中样式(继承上方默认样式) */
.ShowCss {
  opacity: 1;
}

/* 两个按钮共有的样式,也可直接使用箭头图片替代 */
.leftBtn,
.rightBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(109, 109, 109, 0.445);
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  opacity: 0.3;
}

.rightBtn:hover {
  opacity: 1 !important;
}

.leftBtn:hover {
  opacity: 1 !important;
}

.leftBtn {
  left: 5%;
}

.rightBtn {
  right: 5%;
}

/* 下方指示器盒子 */
.instBox {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  display: flex;
}

/* 小圆点 */
.inst {
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  margin-right: 8px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
}

.inst:last-child {
  margin-right: 0px;
}

.instActv {
  width: 40px;
  height: 15px;
  border-radius: 8px;
  border: 1px solid #3964E6;
  background: #3964E6;
}

#app {
  width: 100%;
}
</style>>