<template>
    <div class="boxs">
        <div class="titles">我的订单</div>
        <div class="content">
            <div class="cuts">
                <div :class="it.name == name ? 'cutActive' : 'cut'" v-for="(it, idx) in cutList" :key="idx"
                    @click="cut(it.name, idx)">{{ it.name }}
                </div>
            </div>
            <div class="table">
                <el-table :data="list" style="width: 100%" stripe
                    :header-cell-style="{ background: '#EFF2FF', color: '#A2AAB4' }">
                    <el-table-column label="产品封面" width="180" align="center">
                        <template>
                            <img src="../../images/编组 2备份 5@2x.png" alt="">
                        </template>
                    </el-table-column>
                    <el-table-column prop="Name" label="产品名称" align="center">
                    </el-table-column>
                    <el-table-column prop="Price" label="价格" align="center">
                    </el-table-column>
                    <el-table-column prop="StatusName" label="状态" align="center">
                    </el-table-column>
                    <el-table-column prop="CreateTime" width="180" label="创建时间" align="center">
                    </el-table-column>
                    <el-table-column prop="PayTime" width="180" label="付款时间" align="center">
                    </el-table-column>
                </el-table>
                <div style="height: 60px; margin-top: 10px">
                    <!-- 分页 -->
                    <el-pagination layout=" sizes,prev, pager, next, jumper" :page-size="10" :page-sizes="[5]"
                        :total="total" @current-change="hCurrentChange" @size-change="hSizeChange" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GetFrontOrderInfoList } from "@/api/vip";
import { getId } from "@/utils/auth.js";
export default {
    name: 'VoluntaryReportingMyOrder',

    data() {
        return {
            total: 0,
            name: '全部',
            obj: {
                Status: -1,
                CustomerId: getId(),
                PageIndex: 1,
                PageSize: 5,
            },
            list: [],
            cutList: [
                {
                    name: '全部',
                },
                {
                    name: '已支付',
                },
                {
                    name: '未支付',
                },
            ],
        };
    },

    mounted() {
        this.getList()
    },

    methods: {
        // 会自动接收当前点击的页码
        hCurrentChange(curPage) {
            // 1. 更新页码
            this.obj.PageIndex = curPage
            // 2. 重发请求
            this.getList()
        },
        // 每页几条
        hSizeChange(curSize) {
            // 1. 更新每页的条数
            this.obj.PageSize = curSize

            // 2. 重发请求
            this.getList()
        },
        cut(name, idx) {
            this.obj.PageIndex = 1
            this.name = name
            if (idx == 0) {
                this.obj.Status = -1
            }
            if (idx == 1) {
                this.obj.Status = 1
            }
            if (idx == 2) {
                this.obj.Status = 0
            }
            this.list = []
            this.getList()
        },
        // 获取个人信息
        async getList() {
            if (this.obj.Status == -1) {
                delete this.obj.Status
            }
            const res = await GetFrontOrderInfoList(
                {
                    ...this.obj
                }
            )
            this.list = []
            this.total = 0
            this.list = res.data.response.data
            this.total = res.data.response.dataCount
        },
    },
};
</script>

<style lang="less" scoped>
img {
    width: 100%;
    height: 100%;
}

.boxs {
    background: #fff;
}

.titles {
    font-size: 22px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 73px;
}

.content {
    .cuts {
        display: flex;
        margin-bottom: 27px;

        .cut {
            cursor: pointer;
            position: relative;
            font-size: 20px;
            color: #999999;
            transform-style: preserve-3d;
            margin-right: 65px;
        }

        .cutActive {
            cursor: pointer;
            position: relative;
            font-size: 20px;
            color: #3964E6;
            transform-style: preserve-3d;
            margin-right: 65px;
        }

        .cutActive::before {
            content: '';
            position: absolute;
            bottom: -10px;
            z-index: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 20px;
            height: 3px;
            background: #3964E6;
            border-radius: 30px;
        }

    }
}
</style>