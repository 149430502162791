import request from "@/utils/request";

// 获取院校库
export const GetUniversitys = (data) => {
  return request({
    method: "post",
    url: `/api/front/Library/GetUniversitys`,
    data,
  });
};
