import request from "@/utils/request";

// PC志愿表新增
export const PostPcVolunteer = (data) => {
  return request({
    method: "post",
    url: `/api/front/PcVolunteer/Post`,
    data,
  });
};

// Pc端查询我的志愿列表
export const GetPcVolunteer = (params) => {
  return request({
    method: "get",
    url: `/api/front/PcVolunteer/GetPage`,
    params,
  });
};

// Pc删除志愿表
export const Delete = (id) => {
  return request({
    method: "delete",
    url: `/api/front/PcVolunteer/Delete/` + id,
  });
};

// Pc查询单个志愿表详情信息
export const PcVolunteerGet = (params) => {
  return request({
    method: "get",
    url: `/api/front/PcVolunteer/Get`,
    params,
  });
};

// Pc修改志愿表
export function PcVolunteerPut(data) {
  return request({
    url: "/api/front/PcVolunteer/Put",
    method: "put",
    data,
  });
}
