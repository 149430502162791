<template>
    <div class="box" style="max-width: 1200px;margin: 0 auto;">
        <div class="my-zytable-page-title page-title-bl4 mt60 mb15">
            <span class="pull-left">志愿表</span>
            <!-- <span class="pull-right f14 my-zytable-filter"><b class="text-primary active">个人</b>|<b>专家</b></span> -->
        </div>
        <div class="tableList">
            <el-table border :data="list" align="center" style="width: 100%"
                :header-cell-style="{ background: '#eaeaea', color: '#606266', fontWeight: 400 }">
                <el-table-column type="index" label="序号" align="center" width="50" />
                <el-table-column prop="VolunteerTableName" align="center" width="150">
                    <template slot="header">
                        <div style="text-align: center">
                            <div>志愿表</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="CP" align="center">
                    <template slot="header">
                        <div style="text-align: center">
                            <div>靠谱率</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="批次" align="center">
                    <template slot-scope="scope">
                        <div style="text-align: center">
                            <div>{{ scope.row.BatchName }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="Score" align="center">
                    <template slot="header">
                        <div style="text-align: center">
                            <div>成绩</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="SubjectClaim" align="center">
                    <template slot="header">
                        <div style="text-align: center">
                            <div>科目</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="类型" align="center">
                    <template slot-scope="scope">
                        <div style="text-align: center">
                            <div>{{ scope.row.Type }}</div>
                        </div>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="customerName" align="center">
                    <template slot="header">
                        <div style="text-align: center">
                            <div>状态</div>
                        </div>
                    </template>
                </el-table-column> -->
                <el-table-column prop="ModifyTime" align="center" width="180">
                    <template slot="header">
                        <div style="text-align: center">
                            <div>最后编辑时间</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="200px">
                    <template slot-scope="scope">
                        <el-button type="text" @click="goTY(scope.row.VId)">查看</el-button>
                        <!-- <el-button type="text">重命名</el-button> -->
                        <el-popconfirm title="删除后无法恢复，确定删除吗？" @confirm="getDelete(scope.row.VId)"
                            style="margin-left: 10px;">
                            <el-button type="text" slot="reference">删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
                <!-- 下来框 -->
            </el-table>
            <div style="height: 60px; margin-top: 10px">
                <!-- 分页 -->
                <el-pagination layout="total, sizes,prev, pager, next, jumper" :page-size="obj.PageSize"
                    :page-sizes="[10, 20, 50]" :total="total" @current-change="hCurrentChange" @size-change="hSizeChange" />
            </div>
        </div>
    </div>
</template>

<script>
import { GetPcVolunteer, Delete } from "@/api/zybEditor";
export default {
    name: 'VoluntaryReportingIndex',

    data() {
        return {
            obj: {
                CustomerId: '',
                PageIndex: 1,
                PageSize: 10
            },
            list: [],
            fetchDate: {
                size: 10,
            },
            total: 2
        };
    },

    mounted() {
        setTimeout(() => {
            this.obj.CustomerId = this.$store.state.user.studentInfo.Id
            this.getList()
        }, 100)
    },

    methods: {
        // 删除
        async getDelete(id) {
            console.log(id);
            const res = await Delete(id)
            if (res.data.msg == '删除成功') {
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                this.getList()
            }
        },
        // 获取列表
        async getList() {
            const res = await GetPcVolunteer({
                ...this.obj
            })
            this.list = res.data.response.data
            this.total = res.data.response.dataCount
            console.log(res.data.response);
        },

        hSizeChange(curSize) {
            this.obj.PageSize = curSize
            this.obj.PageIndex = 1
            this.getList()
        },
        hCurrentChange(curSize) {
            // 1. 更新每页的条数
            this.obj.PageIndex = curSize
            this.getList()
        },
        goTY(VId) {
            this.$router.push({ path: '/Home/zybEditorTwo', query: { VId } })
        }
    },
};
</script>

<style lang="less" scoped>
::v-deep .el-pagination {
    float: right;
    white-space: nowrap;
    padding: 2px 5px;
    color: #303133;
    font-weight: 700;
    margin-top: 30px !important;
}

.page-title-bl4 {
    border-color: #2577e3 !important;
}

.page-title-bl4 {
    border-left: 4px solid;
    color: #333;
    font-size: 20px;
    line-height: 22px;
    height: 22px;
    padding-left: 10px;
    font-weight: 500;
    margin-bottom: 15px !important;
    margin-top: 30px !important;

    .pull-left {
        float: left !important;
    }

    .pull-right {
        float: right !important;
        font-size: 14px !important;
        cursor: pointer;

        b {
            font-weight: bold;
        }

        .text-primary {
            color: #2577e3 !important;
        }
    }
}
</style>