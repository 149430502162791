import request from "@/utils/request";

// 获取专业介绍
export const GetMajorDetail = (params) => {
  return request({
    method: "get",
    url: `/api/front/Library/GetMajorDetail`,
    params,
  });
};

// 获取相关院校
export const GetRelevantSchool = (params) => {
    return request({
      method: "get",
      url: `/api/front/Library/GetRelevantSchool`,
      params,
    });
  };

