<template>
    <div>
        <div class="schoolParticulars">
            <div class="audioAndPhone">
                <el-carousel>
                    <el-carousel-item v-for="(item) in imgs" :key="item">
                        <img :src="item" alt="">
                    </el-carousel-item>
                </el-carousel>
                <div class="btn">
                    <span>视频</span>
                    <span class="spanActive">图片</span>
                </div>
            </div>
            <div class="particulars">
                <div class="rankingBox" v-if="rankingList.length !== 0">
                    <div class="ranking" v-for="(it, idx) in rankingList" :key="idx">
                        <div class="rankingItem">
                            <div class="num">
                                <span>{{ it.Rank }}</span>
                                <span style="font-size: 20px;">名</span>
                            </div>
                            <div class="describes">{{ it.name }}</div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <el-empty description="暂无数据"></el-empty>
                </div>
                <div class="introductory">
                    <p :class="show == true ? 'zk' : 'sq'">{{ Description.Description }}</p>
                    <span v-if="!show" @click="unfold(true)">更多...</span>
                    <span v-else @click="unfold(false)">收起...</span>
                </div>
            </div>
        </div>
        <div class="table">
            <div class="tit">学科评估</div>
            <el-select size="mini" style=";margin-bottom: 10px;width: 100px;" v-model="value22" placeholder="请选择"
                @change="provinceChange($event)">
                <el-option v-for="item in options22" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <div class="tab" v-if="tableData.length !== 0">
                <el-table border :data="tableData" stripe style="width: 100%"
                    :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">
                    <el-table-column label="数量" width="180">
                        <template>
                            {{ tableData[0].length }}
                        </template>
                    </el-table-column>
                    <el-table-column label="学科">
                        <template slot-scope="scope">

                            <span v-for="(it, idx) in scope.row" :key="idx"
                                style="display: inline-block; margin:10px 20px 20px ;">
                                {{ it.name }} {{ it.level }}
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div v-else>
                <el-empty description="暂无推荐数据"></el-empty>
            </div>
        </div>
        <div class="table">
            <div class="tit">特色专业</div>
            <div class="tab" v-if="tableData1.length !== 0">
                <el-table border :data="tableData1" stripe style="width: 100%"
                    :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">

                    <el-table-column label="专业层次" width="180">
                        <template>
                            {{ tableData1[0][0].professionType }}
                        </template>
                    </el-table-column>
                    <el-table-column label="数量" width="180">
                        <template>
                            {{ tableData1[0].length }}
                        </template>
                    </el-table-column>
                    <el-table-column label="专业">
                        <template slot-scope="scope">
                            <span v-for="(it, idx) in scope.row" :key="idx"
                                style="display: inline-block; margin:10px 20px 20px ;">
                                {{ it.name }}
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div v-else>
                <el-empty description="暂无推荐数据"></el-empty>
            </div>
        </div>
        <div class="table">
            <div class="tit">院系设置</div>
            <div class="tab" v-if="tableData2.length !== 0">
                <el-table border :data="tableData2" stripe style="width: 100%"
                    :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">
                    <el-table-column prop="name" label="学院" width="180">
                    </el-table-column>
                    <el-table-column label="所含专业">
                        <template slot-scope="scope">
                            <span v-for="(it, idx) in scope.row.departmentMajors" :key="idx"
                                style="display: inline-block; margin:10px 20px 20px ;">
                                {{ it.name }}
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div v-else>
                <el-empty description="暂无推荐数据"></el-empty>
            </div>
        </div>
    </div>
</template>

<script>
import { GetUniversityRank, GetSubjectEvaluate, GetFeaturedMajor, GetDepartment } from "@/api/schoolDetails";
export default {
    name: 'SchoolParticularsIndex',
    props: {
        Description: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            options22: [{
                value: '第一轮',
                label: '第一轮'
            },
            {
                value: '第二轮',
                label: '第二轮'
            },
            {
                value: '第三轮',
                label: '第三轮'
            },
            {
                value: '第四轮',
                label: '第四轮'
            },
            ],
            value22: '第四轮',
            imgs: [],
            show: false,
            rankingList: [
            ],
            arr: [],

            tableData: [],


            tableData1: [],


            tableData2: []
        };
    },

    mounted() {
        setTimeout(() => {
            this.imgs = JSON.parse(this.Description.Imglist)
            console.log(this.imgs.length);
            if (this.imgs.length > 8) {
                console.log(111);
                this.imgs.splice(8, this.imgs.length - 8)
            }
            this.getRank()
        }, 1000)
    },
    created() {
    },
    methods: {
        async getRank() {
            const res = await GetUniversityRank({
                UniversityName: this.Description.Name
            })
            this.rankingList = res.data.response
            this.rankingList.forEach(it => {
                if (it.Type == 0) {
                    it.name = '校友会'
                } else if (it.Type == 1) {
                    it.name = '武书连'
                } else if (it.Type == 2) {
                    it.name = '软科 '
                } else if (it.Type == 3) {
                    it.name = 'QS'
                } else if (it.Type == 4) {
                    it.name = '泰晤士河报'
                } else if (it.Type == 5) {
                    it.name = '热度'
                }
            });
            this.getSubjectEvaluateList()
            this.getFeaturedMajor()
            this.getDepartment()
        },
        async getSubjectEvaluateList() {
            const res = await GetSubjectEvaluate({
                collegeCode: this.Description.collegeCode,
                extTypeId: this.value22,
            })
            this.tableData = []
            var obj = {}
            obj = res.data.response.subjectEvaluates
            this.tableData.push(obj)
        },
        async getFeaturedMajor() {
            const res = await GetFeaturedMajor({
                collegeCode: this.Description.collegeCode
            })
            var obj = {}
            obj = res.data.response.countries
            this.tableData1.push(obj)
        },
        async getDepartment() {
            const res = await GetDepartment({
                collegeCode: this.Description.collegeCode
            })
            this.tableData2 = res.data.response
        },
        provinceChange() {
            this.getSubjectEvaluateList()
        },
        unfold(show) {
            if (show) {
                this.show = show
            } else {
                this.show = show
            }
        }
    },
};
</script>

<style lang="less" scoped>
::v-deep .el-carousel__button {
    width: 20px !important;
}

::v-deep .el-carousel__container {
    height: 270px;
}

img {
    width: 100%;
    height: 100%;
}

.schoolParticulars {
    margin-top: 60px;
    display: flex;

    .audioAndPhone {
        position: relative;
        width: 460px;
        height: 270px;
        margin-right: 25px;

        .btn {
            position: absolute;
            right: 35px;
            bottom: 25px;
            width: 190px;
            height: 36px;
            background: rgba(255, 255, 255, 0.6);
            border-radius: 20px;
            display: flex;
            z-index: 11;

            span {
                display: block;
                width: 95px;
                height: 36px;
                border-radius: 20px;
                font-size: 20px;
                color: #333;
                text-align: center;
                line-height: 36px;
                cursor: pointer;
            }

            .spanActive {
                background: #FFFFFF;
            }
        }
    }

    .particulars {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;

        .rankingBox {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .ranking {
                width: 105px;
                height: 119px;
                background: #F0F4FF;
                box-shadow: 0px 3px 10px 0px rgba(209, 209, 209, 0.5);
                border-radius: 10px;

                .rankingItem {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    font-family: PingFangSC-Semibold, PingFang SC;

                    .num {
                        font-size: 28px;
                        font-weight: 600;
                        color: #FFAA47;
                    }

                    .describes {
                        text-align: center;
                        width: 70px;
                        color: #999999;
                        font-size: 14px;
                    }
                }
            }
        }


        .introductory {

            margin-top: 10px;
            box-sizing: border-box;
            width: 100%;
            background: #FFFFFF;
            box-shadow: 0px 3px 10px 0px rgba(209, 209, 209, 0.5);
            border-radius: 10px;
            font-size: 14px;
            padding: 16px 29px;

            .sq {
                height: 90px;
                color: #666;
                overflow: hidden;
                text-align: justify;
                line-height: 20px;
            }

            .zk {
                overflow: hidden;
            }

            span {
                cursor: pointer;
                color: #FFAA47;
            }
        }
    }
}

.table {
    margin-top: 40px;

    .tit {
        font-weight: bold;
        font-size: 28px;
        margin-bottom: 30px;
    }
}
</style>