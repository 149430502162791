<template>
    <div id="pdfcontent" style="max-width: 1200px;margin: 0 auto;" v-loading="loading">
        <div class="titleBox">
            <div class="left">
                <!-- <div class="bl">
                    <zCircle :value="'24'" text="24%" :duration="'1'" :size="'40'" :strokeWidth="'10'" :textSize="'20'" />
                </div> -->
                <div class="zybG">
                    <div class="title">{{ subMitObj.volunteerTableName }}</div>
                    <div class="cj">
                        我的成绩：
                        <span>{{ subMitObj.batchName }}</span> <span>{{ subMitObj.score }}分</span>
                        {{ subMitObj.subjectClaim }}
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="all">
                    <div class="cbw">
                        <div class="cbw_all">
                            <div class="cbw_c"></div> <span>冲({{ c }})</span>
                        </div>
                        <div class="cbw_all">
                            <div class="cbw_b"></div> <span>稳({{ w }})</span>
                        </div>
                        <div class="cbw_all">
                            <div class="cbw_w"></div> <span>保({{ b }})</span>
                        </div>
                    </div>
                    <div class="action-all">
                        <span class="action-button" @click="gozyTable()">
                            保存
                        </span>
                        <span class="action-button" @click="downloadPdf('print')">
                            下载
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 表格 -->
        <div class="tableBox">
            <div class="recommend-data-eu-table___J2Nqt" id="table_count">
                <div class="recommend-data-eu-table-hd-box___UexWu">
                    <div class="recommend-data-eu-table-hd___Ep8R8">
                        <div class="recommend-data-eu-table-ratio___TwQdX"><span>排序</span>
                        </div>
                        <div class="recommend-data-eu-table-ratio___TwQdX"><span>录取概率</span>
                        </div>
                        <div class="recommend-data-eu-table-major-count___LhMrX">专业</div>
                        <div class="recommend-data-eu-table-eu-name___fRm_H"><span>院校</span>
                        </div>
                        <div class="recommend-data-eu-table-plan___sNM8l"><span>2023年招生计划</span>
                        </div>
                        <div class="recommend-data-eu-table-admission-data___a50VQ">
                            <div class="year-hd___eE2lN">往年</div>
                            <div class="year-item___NKiGo">2023</div>
                            <div class="year-item___NKiGo">2022</div>
                            <div class="year-item___NKiGo">2021</div>
                        </div>
                        <div class="recommend-data-eu-table-major-count___Zy">专业</div>
                    </div>
                </div>
                <template v-if="!loading && list.length !== 0">
                    <div v-for="(it, idx) in list" :key="idx" class="item">
                        <div class="recommend-data-eu-table-item___BXnAP element">
                            <div class="recommend-data-eu-table-ratio___TwQdX">
                                <el-popover placement="bottom" width="200" trigger="click">
                                    <div class="zy-sort-wrapper">
                                        <div class="pp">第
                                            <div class="el-input el-input--small"><input type="text" autocomplete="off"
                                                    v-model="value" placeholder="N" controls-position="right" min="1"
                                                    class="el-input__inner">
                                            </div>
                                            <span>志愿</span>
                                        </div>
                                        <button type="button" class="el-button el-button--primary el-button--small"
                                            @click="alter(idx + 1)"><span>确定</span>
                                        </button>
                                    </div>
                                    <div slot="reference" class="ratio-num___gAwOo" style="cursor: pointer;"> {{ idx + 1 }}
                                        <span>
                                            <i class="el-icon-arrow-down major-icon"></i>
                                        </span>
                                    </div>
                                </el-popover>
                            </div>
                            <div class="recommend-data-eu-table-ratio___TwQdX">
                                <div class="ratio-num___gAwOo">{{ it.Percentage }}%</div>
                                <p :class="it.planMajorItems[0].Type == 2 ? 'c' : it.planMajorItems[0].Type == 1 ? 'w' : 'b'"><span>{{ it.planMajorItems[0].Type == 2 ? '冲' :
                                    it.planMajorItems[0].Type == 1 ? '稳' : '保' }}</span></p>
                            </div>
                            <div class="recommend-data-eu-table-major-count___LhMrX">
                                <div class="count-box___NYb3B">
                                    <div
                                        style="font-size: 14px;font-weight: 400;color: #272727;line-height: 20px; padding: 17px 0;">
                                        {{ it.planMajorItems[0].Major }}</div>
                                    <div style="font-size: 14px;font-weight: 400;color: #272727;">
                                        {{ it.planMajorItems[0].MajorRemark == null ? '-' :
                                            it.planMajorItems[0].MajorRemark
                                        }}</div>
                                    <div class="sch-line___oPJFW">专业代码 {{ it.planMajorItems[0].MajorCode }}</div>
                                </div>
                            </div>
                            <div class="recommend-data-eu-table-eu-name___fRm_H">
                                <div class="sch-name___afuQF"><a href="javascript:void(0)" target="_blank">{{
                                    it.planMajorItems[0].UniversityName
                                }}</a></div>
                                <div class="sch-line___oPJFW">{{ it.UniversityName
                                }} </div>
                                <!-- 综合 公办 -->
                                <div class="sch-line___oPJFW">
                                    <span v-if="it._985 !== '否'">985</span>
                                    <span v-if="it._211 !== '否'">211</span>
                                    <span v-if="it._SYL !== '否'">双一流</span>
                                </div>
                                <div class="sch-line___oPJFW">隶属于{{ it.Ownership }}</div>
                                <div class="sch-line___oPJFW">代码 {{ it.UniversityCode }}</div>
                            </div>
                            <div class="recommend-data-eu-table-plan___sNM8l">
                                <div class="plan-count___UrtQc">
                                    <div class="plan-count-num___IspKS">10人</div>
                                </div>
                                <div class="plan-info___kz4Ip">4年 ￥{{ it.planMajorItems[0].fee }}/年</div>
                                <div class="plan-subject___wsd5H">不限</div>
                            </div>
                            <div class="recommend-data-eu-table-admission-data___a50VQ">
                                <div class="admission-line___q58Dc">
                                    <div class="year-hd___eE2lN">录取人数</div>
                                    <div class="year-item___NKiGo">{{ it.planMajorItems[0].PlanItems[0] == undefined ? '--'
                                        :
                                        it.planMajorItems[0].PlanItems[0].Count }}</div>
                                    <div class="year-item___NKiGo">{{ it.planMajorItems[0].PlanItems[1] == undefined ? '--'
                                        :
                                        it.planMajorItems[0].PlanItems[1].Count }}</div>
                                    <div class="year-item___NKiGo">{{ it.planMajorItems[0].PlanItems[2] == undefined ? '--'
                                        :
                                        it.planMajorItems[0].PlanItems[2].Count
                                    }}</div>
                                </div>
                                <div class="admission-line___q58Dc">
                                    <div class="year-hd___eE2lN">计划人数</div>
                                    <div class="year-item___NKiGo">{{ it.planMajorItems[0].PlanItems[0] == undefined ? '--'
                                        :
                                        it.planMajorItems[0].PlanItems[0].PlanCount }}</div>
                                    <div class="year-item___NKiGo">{{ it.planMajorItems[0].PlanItems[1] == undefined ? '--'
                                        :
                                        it.planMajorItems[0].PlanItems[1].PlanCount }}</div>
                                    <div class="year-item___NKiGo">{{ it.planMajorItems[0].PlanItems[2] == undefined ? '--'
                                        :
                                        it.planMajorItems[0].PlanItems[2].PlanCount }}</div>
                                </div>
                                <div class="admission-line___q58Dc">
                                    <div class="year-hd___eE2lN">最低分</div>
                                    <div class="year-item___NKiGo">{{ it.planMajorItems[0].PlanItems[0] == undefined ? '--'
                                        :
                                        it.planMajorItems[0].PlanItems[0].Scoreline }}</div>
                                    <div class="year-item___NKiGo">{{ it.planMajorItems[0].PlanItems[1] == undefined ? '--'
                                        :
                                        it.planMajorItems[0].PlanItems[1].Scoreline }}</div>
                                    <div class="year-item___NKiGo">{{ it.planMajorItems[0].PlanItems[2] == undefined ? '--'
                                        :
                                        it.planMajorItems[0].PlanItems[2].Scoreline }}</div>
                                </div>
                                <div class="admission-line___q58Dc">
                                    <div class="year-hd___eE2lN">最低位次</div>
                                    <div class="year-item___NKiGo">{{ it.planMajorItems[0].PlanItems[0] == undefined ? '--'
                                        :
                                        it.planMajorItems[0].PlanItems[0].RankLine }}</div>
                                    <div class="year-item___NKiGo">{{ it.planMajorItems[0].PlanItems[1] == undefined ? '--'
                                        :
                                        it.planMajorItems[0].PlanItems[1].RankLine }}</div>
                                    <div class="year-item___NKiGo">
                                        {{ it.planMajorItems[0].PlanItems[2] == undefined ? '--' :
                                            it.planMajorItems[0].PlanItems[2].RankLine }}
                                    </div>
                                </div>
                            </div>
                            <div class="recommend-data-eu-table-major-count___Zy">
                                <div class="count-box___NYb3B">
                                    <div class="count___QftTC">
                                        <span class="handle" style="cursor: pointer; margin-right: 30px;">
                                            <i class="el-icon-rank" style="font-size: 20px;"></i>
                                        </span>
                                        <el-popconfirm title="删除后无法恢复，确定删除吗？" @confirm="deld(idx)">
                                            <span slot="reference" style="cursor: pointer;">
                                                <i class="el-icon-delete" style="font-size: 20px;"></i>
                                            </span>
                                        </el-popconfirm>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <div v-else>
                    <el-empty description="暂无数据"></el-empty>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PcVolunteerPut, PcVolunteerGet } from "@/api/zybEditor";
// PcVolunteerPut,
// import zCircle from '@/components/zCircle'
import Sortable from 'sortablejs'
import PdfLoader from '@/utils/html2pdf'
// import { formatTime } from '@/utils/index.js'
export default {
    name: 'VoluntaryReportingIndex',
    components: {
        // zCircle
    },
    data() {
        return {
            pdfDownLoader: '',
            loading: false,
            w: 0,
            b: 0,
            c: 0,
            value: '',
            VId: '',
            list: [],
            sortData: [],
            subMitObj: {
                vId: '',
                customerId: '',
                volunteerTableName: "",
                type: "一键填报",
                subjectClaim: "",
                score: '',
                batchName: '',
                volunteerTableDetailViews: []
            }
        };
    },

    mounted() {
        this.subMitObj.vId = this.$route.query.VId || ''
        this.getVolunteerList()
        this.rowDrop()
        const xxx = document.getElementById('pdfcontent')  // 需要导出部分页面的id名
        this.pdfDownLoader = new PdfLoader(xxx, 'fileName', 'question-table')  // fileName -->导出文件名,  question-table -->防止被截断的class名
    },
    methods: {
        downloadPdf() {
            this.pdfDownLoader.outPutPdfFn(JSON.parse(sessionStorage.getItem('setStudentInfo')).NickName + ' - ' + this.subMitObj.volunteerTableName)
        },
        // Pc查询单个志愿表详情信息
        async getVolunteerList() {
            this.loading = true
            const res = await PcVolunteerGet({
                id: this.subMitObj.vId
            })
            this.subMitObj.customerId = res.data.response.CustomerId
            this.subMitObj.type = res.data.response.Type
            this.subMitObj.volunteerTableName = res.data.response.VolunteerTableName
            this.subMitObj.subjectClaim = res.data.response.SubjectClaim
            this.subMitObj.score = res.data.response.Score
            this.subMitObj.batchName = res.data.response.BatchName
            this.list = res.data.response.tableDetailItemsViews
            this.list.forEach((it) => {
                if (it.Type == 2) {
                    this.c += 1
                } else if (it.Type == 1) {
                    this.w += 1
                } else {
                    this.b += 1
                }
                it.planMajorItems.forEach((its) => {
                    it.Percentage = its.Percentage
                })
            })
            this.loading = false
            console.log('详情', res.data.response.tableDetailItemsViews);
        },

        // 排序信息修改提交
        async gozyTable() {
            if (this.list.length !== 0) {
                this.list.forEach((it, idx) => {
                    var obj = {}
                    obj.universityId = it.UniversityId
                    obj.universityName = it.UniversityName
                    obj.universityCode = it.UniversityCode
                    obj.Sort = idx
                    obj.VolunteerRemark = []
                    obj.planMajorIds = String(it.planMajorItems[0].PlanId)


                    it.planMajorItems.forEach(item => {
                        var obj1 = {}
                        obj1.planId = item.PlanId
                        obj1.type = item.Type
                        obj1.tage = item.Percentage
                        obj.VolunteerRemark.push(obj1)
                    })
                    this.subMitObj.volunteerTableDetailViews.push(obj)
                })
                console.log(this.subMitObj)
                await PcVolunteerPut({
                    ...this.subMitObj
                })
                this.subMitObj.volunteerTableDetailViews = []
                this.$router.push('/Home/zyTable')
            } else {
                this.$message.error('保存出错了~')
            }
        },

        // 拖拽
        rowDrop() {
            const el = document.getElementById('table_count')
            Sortable.create(el, {
                sort: true, //是否可进行拖拽排序
                animation: 150,
                // Class name for the drop placeholder,
                handle: ".handle",
                //拖拽结束执行,evt执向拖拽的参数
                onEnd: ({ newIndex, oldIndex }) => {
                    console.log(newIndex, oldIndex);
                    let arr = this.list;
                    let temp = arr[oldIndex - 1]
                    arr.splice(oldIndex - 1, 1)
                    arr.splice(newIndex - 1, -1, temp)
                    this.sortData = arr;
                    this.list = []
                    this.$nextTick(function () {
                        this.list = this.sortData
                        // 重新赋值，用新数据来刷新视图 
                    })
                }
            });
        },

        // 手动输入排列
        alter(oldIndex) {
            console.log(this.value, oldIndex);
            let arr = this.list;
            let temp = arr[oldIndex - 1]
            arr.splice(oldIndex - 1, 1)
            arr.splice(this.value - 1, -1, temp)
            this.sortData = []
            this.sortData = arr;
            this.list = []
            this.$nextTick(function () {
                this.list = this.sortData
                // 重新赋值，用新数据来刷新视图 
                this.value = ''
            })
        },

        // 删除
        deld(index) {
            this.list.splice(index, 1)
        }
    }

};
</script>

<style lang="less" scoped>
.zy-sort-wrapper {
    display: flex;
    align-items: center;

    .pp {
        width: 182px;
        justify-content: center;
        word-break: keep-all;
        margin-right: 5px;
        display: flex;
        align-items: center;

        .el-input--small {
            font-size: 13px;
        }
    }

    .pp>div {
        display: inline-block;
        margin: 0 5px;
    }

    .el-button--primary {
        background-color: #2577e3 !important;
        border-color: #2577e3 !important;
        color: #fff !important;
    }
}

.titleBox {
    margin-top: 30px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
        display: flex;
        align-items: center;

        .bl {
            width: 80px;
            height: 80px;
            border-radius: 50%;
        }

        .zybG {
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .title {
                height: 45px;
                font-size: 24px !important;
            }

            .cj {
                margin-top: 10px !important;
                font-size: 12px !important;
            }
        }
    }

    .right {
        display: flex;
        padding: 20px 20px 0 0;
        float: right !important;

        .all {
            width: auto;
            display: flex;
            flex-wrap: nowrap;

            .cbw {
                height: 36px;

                .cbw_all {
                    height: 100%;
                    flex-wrap: nowrap;
                    margin-right: 10px;

                    .cbw_w {
                        width: 10px;
                        height: 10px;
                        background-color: #06c687;
                        border-radius: 10px;
                    }

                    .cbw_b {
                        width: 10px;
                        height: 10px;
                        background-color: #ff9423;
                        border-radius: 10px;
                    }

                    .cbw_c {
                        width: 10px;
                        height: 10px;
                        background-color: #f53f3f;
                        border-radius: 10px;
                    }

                    span {
                        min-width: 40px;
                        max-width: auto;
                        height: 100%;
                        line-height: 36px;
                        font-size: 12px;
                        padding-left: 10px;
                    }
                }
            }

            .action-all {
                width: auto;
                height: 36px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .action-button {
                    display: block;
                    min-width: 45px;
                    max-width: auto;
                    text-align: center;
                    margin-right: 10px;
                    padding: 5px 16px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    cursor: pointer;
                    font-size: 14px;
                    color: #4a4a4a;
                    letter-spacing: 0;
                    user-select: none;
                }
            }

            .cbw,
            .cbw_all {
                width: auto;
                display: flex;
                align-items: center;
            }
        }

    }

    .right>div:first-of-type {
        flex: 1;
    }
}

.tableBox {
    border-radius: 5px;
    padding: 22px 0 20px 0;
}

.recommend-data-eu-table___J2Nqt {
    height: 100%;
    position: relative;

    .recommend-data-eu-table-hd-box___UexWu {
        position: relative;
        height: 39px;

        .recommend-data-eu-table-hd___Ep8R8 {
            display: flex;
            width: 100%;
            height: 39px;
            background-color: #f4f6f8;
            border: solid #efefef;
            border-width: 1px 1px 0;
            line-height: 39px;
            font-size: 14px;
            font-weight: 400;
            color: #9d9d9d;

            .recommend-data-eu-table-eu-name___fRm_H {
                flex: 5 1;
            }

            .recommend-data-eu-table-major-count___LhMrX {
                flex: 1 1;
                min-width: 145px;
                justify-content: center;
            }

            .recommend-data-eu-table-major-count___Zy {
                width: 80px;
                justify-content: center;
            }

            .recommend-data-eu-table-ratio___TwQdX {
                width: 60px;
            }

            .recommend-data-eu-table-plan___sNM8l {
                width: 110px;
            }

            .recommend-data-eu-table-admission-data___a50VQ {
                flex: 4 1;
                min-width: 339px;
                display: flex;
                padding: 0 !important;
                border-right-width: 0 !important;

                .year-hd___eE2lN {
                    flex: 58 1;
                    border-right: 1px solid #efefef;
                    text-align: center;
                }

                .year-item___NKiGo {
                    flex: 89 1;
                    border-right: 1px solid #efefef;
                    text-align: center;
                }
            }
        }

        .recommend-data-eu-table-hd___Ep8R8>div {
            padding: 0 15px;
            border-right: 1px solid #efefef;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .recommend-data-eu-table-item___BXnAP>div:nth-child(4) {
        align-items: flex-start;
    }

    .recommend-data-eu-table-item___BXnAP {
        width: 100%;
        min-height: 190px;
        border: 1px solid #efefef;
        margin-bottom: 11px;
        position: relative;
        display: flex;



        .recommend-data-eu-table-eu-name___fRm_H {
            flex: 5 1;

            .sch-name___afuQF {
                font-size: 18px;
                font-weight: 600;
                color: #272727;
                line-height: 25px;
                margin-bottom: 5px;
                cursor: pointer;

                a {
                    color: #272727;
                    text-decoration: none;
                    background-color: transparent;
                    outline: none;
                    cursor: pointer;
                    transition: color .3s;
                }

            }

            .sch-line___oPJFW {
                font-size: 14px;
                font-weight: 400;
                color: #646464;
                line-height: 20px;
                margin-bottom: 5px;
                white-space: pre-wrap;
            }
        }

        .recommend-data-eu-table-major-count___LhMrX {
            flex: 1 1;
            justify-content: center;
            min-width: 145px;

            .count-box___NYb3B {
                width: 100%;

                .sch-line___oPJFW {
                    font-size: 14px;
                    font-weight: 400;
                    color: #646464;
                    line-height: 20px;
                    margin-bottom: 5px;
                    white-space: pre-wrap;
                }
            }
        }

        .recommend-data-eu-table-major-count___Zy {
            justify-content: center;
            width: 80px;

            .count-box___NYb3B {
                .count___QftTC {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .recommend-data-eu-table-ratio___TwQdX {
            width: 60px;

            .ratio-num___gAwOo {
                font-size: 20px;
                font-family: DIN Alternate Bold;
                color: #272727;
                line-height: 24px;
                margin-bottom: 5px;
            }

            p {
                font-size: 12px;
                user-select: none;
                color: #fff !important;
                letter-spacing: 0;
                line-height: 18px;
                width: 24px;
                height: 24px;
                border-radius: 2px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .c {
                background-color: #f53f3f;
            }

            .w {
                background-color: #ff9423;
            }

            .b {
                background-color: #06c687;
            }
        }

        .recommend-data-eu-table-plan___sNM8l {
            width: 110px;

            .plan-count___UrtQc {
                display: flex;
                width: max-content;

                .plan-count-num___IspKS {
                    flex: 1 1;
                    font-size: 20px;
                    font-family: DIN Alternate Bold;
                    color: #272727;
                    line-height: 39px;
                    text-align: right;
                    padding-right: 3px;
                    word-break: keep-all;
                }
            }

            .plan-info___kz4Ip,
            .plan-subject___wsd5H {
                font-size: 14px;
                font-weight: 400;
                color: #646464;
                line-height: 20px;
            }
        }

        .recommend-data-eu-table-admission-data___a50VQ {
            flex: 4 1;
            min-width: 339px;
            display: flex;
            padding: 0 !important;
            border-right-width: 0 !important;
            justify-content: space-between;

            .year-hd___eE2lN,
            .year-item___NKiGo {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .admission-line___q58Dc {
                border-bottom: 1px solid #efefef;
                flex: 1 1;
                display: flex;
                width: 100%;

                .year-item___NKiGo {
                    flex: 89 1;
                    border-right: 1px solid #efefef;
                    text-align: center;
                }

                .year-hd___eE2lN {
                    flex: 58 1;
                    border-right: 1px solid #efefef;
                    text-align: center;
                }
            }
        }
    }

    .recommend-data-eu-table-item___BXnAP>div {
        padding: 15px;
        border-right: 1px solid #efefef;
        height: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }


}

#tzy-selected-pop.slide-out {
    transform: translateX(0);
}
</style>