var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"compBox",staticStyle:{"max-width":"1200px","margin":"0 auto"}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"table"},[_c('div',{staticClass:"tit"},[_vm._v("院校招生计划对比")]),_c('div',{staticClass:"selectBox"},[_c('el-select',{staticStyle:{"width":"100px","margin-right":"5px"},attrs:{"size":"small","placeholder":"请选择"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('el-select',{staticStyle:{"width":"100px","margin-right":"5px"},attrs:{"size":"small","placeholder":"请选择"},model:{value:(_vm.value1),callback:function ($$v) {_vm.value1=$$v},expression:"value1"}},_vm._l((_vm.options1),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('el-select',{staticStyle:{"width":"250px","margin-right":"5px"},attrs:{"size":"small","placeholder":"请选择"},model:{value:(_vm.value2),callback:function ($$v) {_vm.value2=$$v},expression:"value2"}},_vm._l((_vm.options2),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_vm._m(0),_vm._m(1),_c('div',{staticClass:"tab"},[_c('table',{staticClass:"my-table body"},[_c('tbody',{staticClass:"my-table-body"},[_vm._m(2),_vm._l((10),function(it){return _c('tr',{key:it},[_vm._m(3,true),_vm._m(4,true),_vm._m(5,true),_vm._m(6,true)])})],2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"31px"}},[_c('div',{staticClass:"pl20 dir-td f14 fw600"},[_vm._v(" 招生方向：清华大学(A003) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"36px"}},[_c('table',{staticClass:"my-table head",staticStyle:{"top":"154px","width":"100%"}},[_c('thead',{staticClass:"my-table-head fw400"},[_c('th',{attrs:{"width":"100"}},[_vm._v("代码")]),_c('th',{staticClass:"text-left pl20"},[_vm._v("专业名称")]),_c('th',{attrs:{"width":"120"}},[_vm._v("23年招生")]),_c('th',{attrs:{"width":"80"}},[_vm._v("22年招生")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"stripe"},[_c('td',{staticClass:"text-center",attrs:{"width":"100"}},[_c('span',{staticClass:"cntext fw600"},[_vm._v("A003")])]),_c('td',{staticClass:"pt20 pb20 pl20"},[_c('span',{staticClass:"cntext fw600 f14 text-color-title"},[_vm._v("清华大学(A003)")])]),_c('td',{staticClass:"text-center",attrs:{"width":"120"}},[_c('span',{staticClass:"cntext fw600"},[_vm._v("一")])]),_c('td',{staticClass:"text-center",attrs:{"width":"80"}},[_c('span',{staticClass:"cntext fw600"},[_vm._v("一")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center",attrs:{"width":"100"}},[_c('span',{staticClass:"cntext"},[_vm._v("1A")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"pt20 pb20 pl20"},[_c('a',{staticClass:"flex-column text-color-title",attrs:{"href":"http://zy.ycymedu.com/majors/small?code=140101","target":"_blank"}},[_c('span',{staticClass:"cntext fw600 f14"},[_vm._v("理科实验班类(1A)")]),_c('span',{staticClass:"cntext f12 text-999 mt5"},[_vm._v("(物理基础类)物理学(等专业)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center",attrs:{"width":"120"}},[_c('span',{staticClass:"cntext"},[_vm._v("10人")]),_c('span',{staticClass:"tag"},[_vm._v("-5")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center",attrs:{"width":"80"}},[_c('span',{staticClass:"cntext"},[_vm._v("199人")])])
}]

export { render, staticRenderFns }