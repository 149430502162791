<template>
    <div class="box">
        <div style="max-width: 1200px;margin: 0 auto;">
            <div class="articleCont clearfix">
                <div class="articleCont_left">
                    <p class="article_list_title">{{ content.Title }}</p>
                    <p class="article_list_shuxing">
                        <span>发布时间：{{ content.CreateTime }}</span>
                    </p>
                    <div id="bbb" class="article_nei">
                        <h1 deep="12">
                            <div v-html="content.Detail"></div>
                            <br>
                            <div style="font-size: medium; text-align: right;"><span style="color: inherit;">{{
                        content.Author }}</span>
                            </div>
                            <div style="text-align: right;"><span><br></span></div><span>
                                <div style="text-align: right;"><span style="color: inherit;">
                                        {{ content.CreateTime.split(' ')[0] }}
                                    </span></div>
                            </span>
                            <p></p>
                        </h1>
                    </div>
                    <div class="laiyuan"><img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAICAYAAAA1BOUGAAAAwUlEQVQYV1XOIQ7CQBAF0D9bhaBcAIEg4Q5cAAEK0t1VtdWQYEDVkJAQDkBS3WybYrFwAySaExQBqdkdsiQVfPlfZvIJAKqq6ltrNwBeADpN02zjOH6TR2PMvq7rNEmST57ngyAI5lLKY4sHpdQaAGdZ1g3DcBVFUfrDsizHzjlFRFdmnhFRKqV8tpcLIcTEOTcSQtyttTet9ZmMMUtmfmitL/6LT1EUUwBDjzullF/6F997PBFRDcB6ZWYiIgGg9wUc+lGOnpbi2QAAAABJRU5ErkJggg==">
                        来源：教育部</div>
                    <!-- <div class="article_tuijian">
                        <p>推荐文章</p>
                        <div class="no-recmend">暂无数据</div>
                    </div> -->
                </div>
                <!--  -->
                <div class="right-slide-container">
                    <div class="articleCont_right">
                        <div class="articleCont_right_zhengce" v-if="typeIndex == 0 || typeIndex == 1">
                            <p>热门资讯</p>
                            <li class="policy clearfix" v-for="(it, idex) in list" :key="idex" @click="getNewS(it.Id)">
                                <div class="policy_pai">{{ idex + 1 }}</div>
                                <p class="policy_title">{{ it.Title }}</p>
                            </li>
                        </div>
                        <!-- <div class="articleCont_right_zhengce" v-if="typeIndex == 0 || typeIndex == 2">
                            <p>最新政策</p>
                            <li class="policy clearfix">
                                <a href="https://www.youjiazhiyuan.cn//articlelist?id=1038"
                                    title="2023强基计划13大变化趋势！给初高中低年级哪些重大启示？"
                                    class="hidden-alink-spider-only">2023强基计划13大变化趋势！给初高中低年级哪些重大启示？</a>
                                <div class="policy_pai">1</div>
                                <p class="policy_title">2023强基计划13大变化趋势！给初高中低年级哪些重大启示？</p>
                            </li>
                            <li class="policy clearfix">
                                <a href="https://www.youjiazhiyuan.cn//articlelist?id=1037" title="多个省份公布2024年高考报名时间"
                                    class="hidden-alink-spider-only">多个省份公布2024年高考报名时间</a>
                                <div class="policy_pai">2</div>
                                <p class="policy_title">多个省份公布2024年高考报名时间</p>
                            </li>
                            <li class="policy clearfix">
                                <a href="https://www.youjiazhiyuan.cn//articlelist?id=1036" title="强基计划物理专业考生就业前景如何？"
                                    class="hidden-alink-spider-only">强基计划物理专业考生就业前景如何？</a>
                                <div class="policy_pai">3</div>
                                <p class="policy_title">强基计划物理专业考生就业前景如何？</p>
                            </li>
                            <li class="policy clearfix">
                                <a href="https://www.youjiazhiyuan.cn//articlelist?id=1035" title="全国院校层次划分解读"
                                    class="hidden-alink-spider-only">全国院校层次划分解读</a>
                                <div class="policy_pai">4</div>
                                <p class="policy_title">全国院校层次划分解读</p>
                            </li>
                            <li class="policy clearfix">
                                <a href="https://www.youjiazhiyuan.cn//articlelist?id=1034" title="想报考综评强基高中三年如何准备？提前规划才不后悔"
                                    class="hidden-alink-spider-only">想报考综评强基高中三年如何准备？提前规划才不后悔</a>
                                <div class="policy_pai">5</div>
                                <p class="policy_title">想报考综评强基高中三年如何准备？提前规划才不后悔</p>
                            </li>
                            <li class="policy clearfix">
                                <a href="https://www.youjiazhiyuan.cn//articlelist?id=1033"
                                    title="上海纽约大学2024年综合评价报名系统填报指南&amp;常见问题"
                                    class="hidden-alink-spider-only">上海纽约大学2024年综合评价报名系统填报指南&amp;常见问题</a>
                                <div class="policy_pai">6</div>
                                <p class="policy_title">上海纽约大学2024年综合评价报名系统填报指南&amp;常见问题</p>
                            </li>
                            <li class="policy clearfix">
                                <a href="https://www.youjiazhiyuan.cn//articlelist?id=1032" title="2024年综合评价报名正式启动！报名流程梳理！"
                                    class="hidden-alink-spider-only">2024年综合评价报名正式启动！报名流程梳理！</a>
                                <div class="policy_pai">7</div>
                                <p class="policy_title">2024年综合评价报名正式启动！报名流程梳理！</p>
                            </li>
                            <li class="policy clearfix">
                                <a href="https://www.youjiazhiyuan.cn//articlelist?id=1031" title="2023年度海军招飞全检定选工作安排"
                                    class="hidden-alink-spider-only">2023年度海军招飞全检定选工作安排</a>
                                <div class="policy_pai">8</div>
                                <p class="policy_title">2023年度海军招飞全检定选工作安排</p>
                            </li>
                            <li class="policy clearfix">
                                <a href="https://www.youjiazhiyuan.cn//articlelist?id=1030"
                                    title="2024年度海军招收选拔飞行学员简章（面向普通高中毕业生）"
                                    class="hidden-alink-spider-only">2024年度海军招收选拔飞行学员简章（面向普通高中毕业生）</a>
                                <div class="policy_pai">9</div>
                                <p class="policy_title">2024年度海军招收选拔飞行学员简章（面向普通高中毕业生）</p>
                            </li>
                            <li class="policy clearfix">
                                <a href="https://www.youjiazhiyuan.cn//articlelist?id=1029"
                                    title="2024年度西南五省（市区）空军招收飞行学员简章发布"
                                    class="hidden-alink-spider-only">2024年度西南五省（市区）空军招收飞行学员简章发布</a>
                                <div class="policy_pai">10</div>
                                <p class="policy_title">2024年度西南五省（市区）空军招收飞行学员简章发布</p>
                            </li>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GetPageNews, GetDetail } from "@/api/News";
export default {
    name: 'VoluntaryReportingIndex',

    data() {
        return {
            content: {},
            id: '',
            typeIndex: 0,
            PageIndexNum: 0,//页数
            pageCount: 0,//总数
            obj: {
                CategoryId: 1,
                PageIndex: 1,
                PageSize: 10,
            },
            list: [],
            typeList: [
                {
                    name: '全部',
                    type: 1
                },
                {
                    name: '高考头条',
                    type: 1
                },
                {
                    name: '最新政策',
                    type: 2
                },
            ]
        };
    },

    mounted() {
        this.id = this.$route.query.id
        this.getList()
        this.getNewS(this.id)
    },

    methods: {
        qiehuan(type, index) {
            this.typeIndex = index
            this.obj.CategoryId = type
            this.getList()
        },
        async getList() {
            const res = await GetPageNews(
                this.obj
            )
            this.list = res.data.response.data
            this.PageIndexNum = res.data.response.page
            this.pageCount = res.data.response.pageCount
            console.log(res);
        },
        // 获取详情数据
        async getNewS(id) {
            const res = await GetDetail({
                Id: id
            })
            this.content = res.data.response
        }
    },
};
</script>

<style lang="less" scoped>
* {
    font-family: Microsoft YaHei;
}

.box {
    background: #F5F4F8;
    padding-bottom: 10px;
}

.clearfix {
    zoom: 1;
}

.hidden-alink-spider-only {
    display: none;
}

a,
a:hover {
    color: #999;
    text-decoration: none;
}

li {
    list-style: none;
}

.articleCont {
    padding-top: 42px;

    .articleCont_left {
        width: 880px;
        background-color: #fff;
        float: left;
        padding-bottom: 28px;
        border-radius: 14px;
        margin-bottom: 150px;

        .article_list_title {
            font-size: 28px;
            font-weight: 600;
            color: #333;
            line-height: 32px;
            margin-left: 50px;
            margin-top: 40px;
            border-top-left-radius: 14px;
            border-top-right-radius: 14px;
            padding-bottom: 1px;
            text-align: center;

        }

        .article_list_shuxing {
            width: 765px;
            font-size: 16px;
            font-weight: 400;
            color: #888;
            line-height: 24px;
            margin-top: 15px;
            margin-bottom: 38px;
            margin-left: 50px;
        }

        .article_nei {
            width: 780px;
            margin: auto;
            margin-bottom: 40px;
            color: #666;
            font-size: 16px;
            line-height: 24px;

            h1 {
                font-size: 16px;
            }
        }

        .laiyuan {
            width: 665px;
            margin: auto;
            text-align: right;
            font-size: 16px;
            font-weight: 200;
            color: #888;

            img {
                width: 11px;
            }
        }

        .article_tuijian>p {
            height: 24px;
            font-size: 24px;
            font-weight: 600;
            color: #333;
            line-height: 24px;
            margin-left: 50px;
            margin-top: 60px;
        }

        .no-recmend {
            font-size: 20px;
            color: #666;
            width: 100%;
            height: 100px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .articleCont_right {
        width: 300px;
        float: left;
        margin-left: 20px;

        .articleCont_right_zhengce>p {
            height: 21px;
            font-size: 20px;
            font-weight: 700;
            color: #333;
            margin-left: 24px;
            margin-top: 24px;
            margin-bottom: 24px;
        }

        .articleCont_right_zhengce {
            width: 100%;
            background-color: #fff;
            border-radius: 14px;
            overflow: hidden;
            margin-bottom: 20px;
            padding-bottom: 6px;

            .policy_title:hover {
                color: #3964E6 !important;
                cursor: pointer;
            }


            .policy {
                width: 280px;
                margin-left: 26px;
                margin-bottom: 20px;

                .policy_pai {
                    font-size: 14px;
                    font-weight: 500;
                    width: 16px;
                    height: 16px;
                    background: transparent;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 16px;
                    color: #888;
                    float: left;
                    margin-top: 3px;
                }

                .policy_title {
                    width: 244px;
                    float: left;
                    margin-left: 8px;
                    font-size: 16px;
                    color: #333;
                    line-height: 22px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

        }
    }
}
</style>