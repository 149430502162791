<template>
    <el-tooltip placement="top" content="返回顶部">
        <transition :name="transitionName">
            <div v-show="visible" :style="customStyle" class="back-to-ceiling" @click="backToTop">
                <svg focusable="false" class="" data-icon="vertical-align-top" width="1.3em" height="1.3em"
                    fill="currentColor" aria-hidden="true" viewBox="64 64 896 896">
                    <path
                        d="M859.9 168H164.1c-4.5 0-8.1 3.6-8.1 8v60c0 4.4 3.6 8 8.1 8h695.8c4.5 0 8.1-3.6 8.1-8v-60c0-4.4-3.6-8-8.1-8zM518.3 355a8 8 0 00-12.6 0l-112 141.7a7.98 7.98 0 006.3 12.9h73.9V848c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V509.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 355z">
                    </path>
                </svg>
            </div>
        </transition>
    </el-tooltip>
</template>
<script>
export default {
    name: "BackToTop",
    props: {
        // 距离顶部多远出现按钮
        visibilityHeight: {
            type: Number,
            default: 100
        },
        // 返回顶部后离顶部的距离
        backPosition: {
            type: Number,
            default: 0
        },
        customStyle: {
            type: Object,
            default: function () {
                return {
                    "right": "40px",
                    "bottom": "40px",
                    "width": "40px",
                    "height": "40px",
                    "border-radius": "20px",
                    "line-height": "50px",
                    "background-color": "#00000073"
                };
            }
        },
        transitionName: {
            type: String,
            default: "fade"
        }
    },
    data() {
        return {
            visible: false,
            interval: null,
            isMoving: false
        };
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
        if (this.interval) {
            clearInterval(this.interval);
        }
    },
    methods: {
        handleScroll() {
            this.visible = window.pageYOffset > this.visibilityHeight;
        },
        backToTop() {
            if (this.isMoving) return;
            const start = window.pageYOffset;
            let i = 0;
            this.isMoving = true;
            this.interval = setInterval(() => {
                const next = Math.floor(this.easeInOutQuad(10 * i, start, -start, 500));
                if (next <= this.backPosition) {
                    window.scrollTo(0, this.backPosition);
                    clearInterval(this.interval);
                    this.isMoving = false;
                } else {
                    window.scrollTo(0, next);
                }
                i++;
            }, 16.7);
        },
        easeInOutQuad(t, b, c, d) {
            if ((t /= d / 2) < 1) return (c / 2) * t * t + b;
            return (-c / 2) * (--t * (t - 2) - 1) + b;
        }
    }
};
</script>
<style lang="less" scoped>
.back-to-ceiling {
    position: fixed;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    transition: all .3s;
    z-index: 999;
    color: #fff
}

.back-to-ceiling:hover {
    background: #d5dbe7;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.back-to-ceiling .Icon {
    fill: #9aaabf;
    background: none;
}
</style>
  
  