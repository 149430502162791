import request from "@/utils/request";

// 获取院校库
export const GetPcUniversityRank = (params) => {
  return request({
    method: "get",
    url: `/api/front/Library/GetPcUniversityRank`,
    params,
  });
};
